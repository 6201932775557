import {
  CHECKLIST_ITEM_BLOCK_ID,
  LIST_ITEM_BLOCK_ID,
  QUOTE_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { ContextToolbarButton } from "@/components/Editor/components/ContextToolbarButton";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import {
  useListTransform,
  ListType,
  getListTypeFromBlock,
} from "@/components/Editor/transforms/useListTransform";
import { allBlocksCanBeTransformed } from "@/components/Editor/transforms/utils";
import { IconFont } from "@/components/Icon";

type ListTransformProps = {
  listType: ListType;
  isContextMenu: boolean;
};

export const ListTransform: React.FC<ListTransformProps> = ({
  listType,
  isContextMenu,
}) => {
  const { getNestingLevel } = useBlockSelection();
  const {
    convertBlocksToList,
    allBlocksAreSameType,
    selectedBlocks,
    parentBlock,
  } = useListTransform();

  const nestingLevel =
    selectedBlocks.length > 0 ? getNestingLevel(selectedBlocks[0]) : 0;
  const isCurrentListFormatActive =
    selectedBlocks.length > 0 &&
    allBlocksAreSameType(selectedBlocks) &&
    getListTypeFromBlock(parentBlock || selectedBlocks[0]) === listType;

  const areAllListBlocks = selectedBlocks.every((block) =>
    [LIST_ITEM_BLOCK_ID, CHECKLIST_ITEM_BLOCK_ID].includes(block.name),
  );

  const isDisabled =
    selectedBlocks.length === 0 ||
    (!areAllListBlocks && !allBlocksCanBeTransformed(selectedBlocks)) ||
    (parentBlock && parentBlock.name === QUOTE_BLOCK_ID) ||
    (nestingLevel > 1 && listType === "checklist");

  const icon = {
    checklist: "checklist",
    unordered: "dashed-list",
    ordered: "ordered-list",
  }[listType] as IconFont;
  const Button = isContextMenu ? ContextToolbarButton : FixedToolbarButton;

  return (
    <Button
      iconName={icon}
      disabled={isDisabled}
      title={listType}
      onClick={() => {
        convertBlocksToList(listType);
      }}
      isActive={isCurrentListFormatActive}
    />
  );
};
