import { Button as WpButton } from "@wordpress/components";
import {
  ButtonProps,
  DeprecatedButtonProps,
} from "@wordpress/components/build-types/button/types";

export const Button: React.FC<ButtonProps & DeprecatedButtonProps> = (
  props,
) => (
  <div
    sx={{
      "& .components-button": {
        width: "100%",
        mt: 3,
        mb: 4,
        display: "block",
        borderRadius: "sm",
        bg: "primary",
        color: "textPrimaryLight",
        transition: "all 0.25s ease-in-out",
        height: "44px",
      },
      "& .components-button:disabled": {
        color: "textPrimaryLight",
        opacity: 0.5,
      },
      "& .components-button:hover:not(:disabled)": {
        color: "textPrimaryLight",
        fontWeight: "medium",
      },
    }}
  >
    <WpButton {...props}>{props.children}</WpButton>
  </div>
);
