import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { TagFilterOptions } from "@/components/Search/FilterPills";
import { FilterSearchBox } from "@/components/Search/FilterSearchBox";
import LineDivider from "@/components/Search/LineDivider";
import { MultipleOptions } from "@/components/Search/MultipleOptions";
import { Pill } from "@/components/Search/Pill";
import { truncateString } from "@/utils/strings";
import { viewStates } from "@/view_state/ViewStates";

interface Props {
  searchViewState: typeof viewStates.search;
  isInModal?: boolean;
}
const MAX_LENGTH = 12;

export const TagFilter: React.FC<Props> = observer(
  ({ searchViewState, isInModal }) => {
    const { __ } = useI18n();
    const { filter, setFilter, tagCounts } = searchViewState;

    const [searchInput, setSearchInput] = useState<string | null>(null);
    const { entryTags: filterTags } = filter;
    const allTagFilterOptions = Object.entries(tagCounts)
      .map(([tag, entryCount]) => ({
        tag,
        entryCount,
      }))
      .sort((a, b) => b.entryCount - a.entryCount);

    const toggleTagFilter = useCallback(
      (tag: string) => {
        const newTags = filterTags.includes(tag)
          ? filterTags.filter((_tag) => _tag !== tag)
          : [...filterTags, tag];

        setFilter({ entryTags: newTags });
      },
      [filterTags, setFilter],
    );

    const selectedTagFilterOptions = useMemo(
      () =>
        allTagFilterOptions.filter((option) =>
          filterTags.includes(option.tag || ""),
        ),
      [filterTags, allTagFilterOptions],
    );
    // 0 tagFilterOptions -> show "Tags"
    // <=2 tagFilterOptions -> truncateString(name, MAX_LENGTH)
    // > 2 tagFilterOptions -> show first tag name, +N
    let pillText = isInModal ? __("All") : __("Tags");
    if (isInModal && selectedTagFilterOptions.length > 0) {
      const truncatedNames = selectedTagFilterOptions.map((option) =>
        truncateString(option.tag || "", MAX_LENGTH),
      );
      if (selectedTagFilterOptions.length > 4) {
        pillText = `${truncatedNames[0]},+${truncatedNames.length - 1}`;
      } else {
        pillText = truncatedNames.join(", ");
      }
    }

    const filtersSelected = filterTags.length;

    const tagsAfterSearch = useMemo(() => {
      if (searchInput && searchInput?.trim().length > 0) {
        const options = allTagFilterOptions.reduce(
          (acc, tagFilterOption) => {
            const name = tagFilterOption.tag?.toLocaleLowerCase();
            const input = searchInput.toLocaleLowerCase().trim();
            const startsWith = name?.startsWith(input);
            const includes = name?.includes(input);
            if (startsWith) {
              acc.startsWith.push(tagFilterOption);
            } else if (includes) {
              acc.includes.push(tagFilterOption);
            }
            return acc;
          },
          {
            startsWith: [] as TagFilterOptions[],
            includes: [] as TagFilterOptions[],
          },
        );
        return [...options.startsWith, ...options.includes];
      }
      return allTagFilterOptions;
    }, [searchInput, allTagFilterOptions]);

    return (
      <D1Dropdown
        focusOnMount
        popoverProps={{
          placement: "bottom-start",
          variant: "primary",
        }}
        onClose={() => {
          searchViewState.setFilterDropdownOpen(false);
        }}
        renderToggle={({ isOpen, onToggle }) => (
          <Pill
            name="tag"
            icon={isInModal ? null : "tag"}
            text={pillText}
            filterCounter={
              !isInModal && filtersSelected > 0 ? filtersSelected : undefined
            }
            isActive={filtersSelected > 0}
            aria-expanded={isOpen}
            fullWidth={isInModal}
            onClear={() => {
              setFilter({ entryTags: [] });
            }}
            onClick={() => {
              searchViewState.setFilterDropdownOpen(true);
              onToggle();
              if (!isOpen) {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: "searchFilter_filterRow_tags",
                });
              }
            }}
          />
        )}
        renderContent={() => (
          <div sx={{ width: "245px", mx: -2, mb: -2 }}>
            <FilterSearchBox
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              placeHolderText={__("Search tags...")}
              onSubmitNullifyInput={true}
              loading={false}
            />
            <LineDivider />
            <div
              sx={{
                overflowY: "auto",
                maxHeight: "350px",
                pb: 2,
                pt: 1,
              }}
            >
              <MultipleOptions
                filterOptions={{
                  type: "tag",
                  options: tagsAfterSearch,
                }}
                selectedOptions={filterTags}
                onOptionToggle={toggleTagFilter}
                noOptionsText={__("No tags available")}
                onToggleAll={() => {
                  if (filterTags.length === tagsAfterSearch.length) {
                    setFilter({ entryTags: [] });
                  } else {
                    setFilter({
                      entryTags: tagsAfterSearch.map((tag) => tag.tag),
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      />
    );
  },
);
