import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { TagManagerModal } from "@/components/Editor/components/TagManagerModal";
import { GlobalEntryID } from "@/data/db/migrations/entry";

type TagManagerProps = {
  entryTags: string[];
  globalEntryID: GlobalEntryID;
  onChangeTags: () => void;
};

export const TagManagerButton: React.FC<TagManagerProps> = observer(
  ({ entryTags, globalEntryID, onChangeTags }) => {
    const { __ } = useI18n();
    const [showManager, setShowManager] = useState(false);

    const onClose = () => {
      setShowManager(false);
    };

    const onOpen = () => {
      setShowManager(true);
    };

    return (
      <div>
        <FixedToolbarButton
          onClick={onOpen}
          title={__("Manage tags")}
          iconName="tag"
        />
        {showManager && (
          <TagManagerModal
            entryTags={entryTags}
            globalEntryID={globalEntryID}
            onChangeTags={onChangeTags}
            onClose={onClose}
          />
        )}
      </div>
    );
  },
);

TagManagerButton.displayName = "TagManagerButton";
