import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { MultipleOptions } from "@/components/Search/MultipleOptions";
import { Pill } from "@/components/Search/Pill";
import { SearchViewState } from "@/view_state/SearchViewState";

type Props = {
  searchViewState: SearchViewState;
  isInModal?: boolean;
};

export const MediaFilter: React.FC<Props> = observer(
  ({ searchViewState, isInModal }) => {
    const { __ } = useI18n();
    const { media: filterMedia } = searchViewState.filter;

    const toggleMediaFilterValue = useCallback(
      (media: string) => {
        const newMedia = filterMedia.includes(media)
          ? filterMedia.filter((_media) => _media !== media)
          : [...filterMedia, media];

        searchViewState.setFilter({ media: newMedia });
      },
      [filterMedia, searchViewState],
    );

    const isActive = !!filterMedia.length;
    let text = isInModal ? __("Any") : __("Media");

    const selectedMedia = [
      filterMedia.includes("photo") && __("Photo"),
      filterMedia.includes("video") && __("Video"),
      filterMedia.includes("audio") && __("Audio"),
      filterMedia.includes("pdf") && __("PDF"),
    ].filter(Boolean);

    const filtersSelected = selectedMedia.length;

    if (isInModal && filtersSelected > 0) {
      if (filtersSelected === 1) {
        text = selectedMedia[0] as string;
      } else {
        text = selectedMedia.join(", ");
      }
    }

    return (
      <D1Dropdown
        focusOnMount
        popoverProps={{
          placement: "bottom-start",
          variant: "primary",
        }}
        onClose={() => {
          searchViewState.setFilterDropdownOpen(false);
        }}
        renderToggle={({ isOpen, onToggle }) => (
          <Pill
            name="media"
            icon={isInModal ? null : "photo"}
            text={text}
            isActive={isActive}
            filterCounter={
              !isInModal && filtersSelected > 0 ? filtersSelected : undefined
            }
            aria-expanded={isOpen}
            fullWidth={isInModal}
            onClear={() => {
              searchViewState.setFilter({ media: [] });
            }}
            onClick={() => {
              searchViewState.setFilterDropdownOpen(true);
              onToggle();
              if (!isOpen) {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: "searchFilter_filterRow_media",
                });
              }
            }}
          />
        )}
        renderContent={() => (
          <div sx={{ width: "245px", mx: -2, mb: -2 }}>
            <div
              sx={{
                overflowY: "auto",
                maxHeight: "350px",
                pb: 2,
                pt: 1,
              }}
            >
              <MultipleOptions
                filterOptions={{
                  type: "media",
                  options: searchViewState.media,
                }}
                selectedOptions={searchViewState.filter.media}
                onOptionToggle={toggleMediaFilterValue}
                noOptionsText={__("No media available")}
                onToggleAll={() => {
                  if (
                    searchViewState.filter.media.length ===
                    searchViewState.media.length
                  ) {
                    searchViewState.setFilter({ media: [] });
                  } else {
                    searchViewState.setFilter({
                      media: searchViewState.media.map((media) => media.media),
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      />
    );
  },
);
