import { Location } from "@/data/db/migrations/entry";

type Props = {
  location: Location;
};

const api_key = process.env.VITE_MAPBOX_KEY;

export const Map: React.FC<Props> = ({ location }) => {
  if (!location || !location.region || !location.region.center) {
    return null;
  }
  const lat = Math.round(location.region.center.latitude * 100) / 100;
  const lon = Math.round(location.region.center.longitude * 100) / 100;

  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-o+000(${lon},${lat})/${lon},${lat},11,0/400x300?access_token=${api_key}`;

  return (
    <div className="map">
      <img crossOrigin="anonymous" src={url} />
    </div>
  );
};
