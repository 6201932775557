import { isDevelopment, isElectronApp } from "@/utils/environment";
import { d1Classes } from "@/D1Classes";
import { EVENT } from "@/analytics/events";
import { JSONSerializable, tracks } from "@/analytics/tracks";
import { UserModel } from "@/data/models/UserModel";

let SEND_TRACKS = true;
let USAGE_STATISTICS = true;

let user: UserModel | null;
let prefix = "";

const premiumStatus = {
  premium: "Premium",
  free: "Trial",
  grandfathered: "Plus",
};

const analytics = {
  initialize: function (initUser: UserModel | null) {
    analytics.setUser(initUser);
    analytics.identifyUser();
    analytics.setPrefix();
  },

  setUser: function (newUser: UserModel | null) {
    user = newUser;
  },

  setPrefix: function () {
    prefix = isElectronApp() ? "dayone_desktop" : "dayone_web";
  },

  tracks: {
    recordEvent: async function (
      eventName: EVENT,
      eventProperties: JSONSerializable = {},
    ) {
      USAGE_STATISTICS = await d1Classes.usageStatisticsStore.get();
      SEND_TRACKS = USAGE_STATISTICS && !isDevelopment();
      const fullEventName = `${prefix}_${eventName}`;

      const fullEventProperties: JSONSerializable = {
        is_signed_in: false,
        device_info_app_version: process.env.APP_VERSION ?? "Unknown",
        ...eventProperties,
      };

      if (user) {
        fullEventProperties["subscription_level"] =
          premiumStatus[user.subscription_status];
        fullEventProperties.is_signed_in = true;
      }

      if (!SEND_TRACKS) {
        console.log(
          `Tracks Event: ${fullEventName}` + " %o",
          fullEventProperties,
        );
      }
      analytics.tracks.validateEvent(fullEventName, fullEventProperties);

      if (prefix && SEND_TRACKS) {
        tracks.recordEvent(fullEventName, fullEventProperties);
      }
    },
    validateEvent: function (
      fullEventName: string,
      fullEventProperties: JSONSerializable,
    ) {
      if (!SEND_TRACKS) {
        return;
      }

      const validName = /^[a-z_][a-z0-9_]*$/;

      if (!validName.test(fullEventName)) {
        throw new Error(`Event ${fullEventName} is invalid`);
      }

      for (const propName in fullEventProperties) {
        if (!validName.test(propName)) {
          throw new Error(
            `Property ${propName} in event ${fullEventName} is invalid`,
          );
        }
      }
    },
  },

  identifyUser: function () {
    // Don't identify the user if we don't have one
    if (!!user) {
      tracks.identifyUser(user.id, user.id);
    }
  },

  clearedIdentity: function () {
    tracks.clearIdentity();
  },
};

export default analytics;

export type Analytics = typeof analytics;
