import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { Icon } from "@/components/Icon";
import { iconButtonHoverBackground } from "@/styles/mixins";

type Props = {
  onClick?: () => void;
  size?: number;
  className?: string;
  label?: string;
};
export const CloseButton: React.FC<Props> = ({
  onClick,
  size,
  className,
  label,
}) => {
  const { __ } = useI18n();
  return (
    <Button
      className={className}
      label={label ? label : __("Close")}
      icon={<Icon icon="close" />}
      iconSize={size}
      onClick={onClick}
      sx={{
        color: "textPrimary",
        position: "absolute",
        opacity: 0.75,
        "&&.components-button": {
          width: size,
          p: 1,
        },
        ...iconButtonHoverBackground,
      }}
    />
  );
};
