import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { Icon } from "@/components/Icon";
import { CloseKeyState } from "@/components/Settings/Account/MasterKeyModal/MasterKeyManage";

export const GenerateKeyComponent: React.FC<{
  generateMasterKey: () => void;
  closeModal: (keySaveState: CloseKeyState) => void;
  agreedToCreateKey: boolean;
  isCreatingSharedJournal: boolean;
}> = ({
  generateMasterKey,
  closeModal,
  agreedToCreateKey,
  isCreatingSharedJournal,
}) => {
  const { __ } = useI18n();
  const [pushedNext, setPushedNext] = useState(agreedToCreateKey);

  useEffect(() => {
    if (agreedToCreateKey !== pushedNext) {
      setPushedNext(agreedToCreateKey);
    }
  }, [agreedToCreateKey]);

  return !pushedNext ? (
    <>
      <Icon icon="shield-lock" size={72} />
      <h2 sx={{ fontWeight: 700, mt: 3 }}>{__("Protect Your Privacy")}</h2>

      <p sx={{ pt: 3, pb: 8, fontSize: 1, lineHeight: 1.5, px: 3 }}>
        {isCreatingSharedJournal
          ? __(
              "Your shared journal is a sacred space for you and the members you invite. Protect it by creating an encryption key and enabling end-to-end encryption.",
            )
          : __(
              "Your journals are a sacred space for your innermost thoughts. Protect them by enabling end-to-end encryption.",
            )}{" "}
        <a
          href="https://dayoneapp.com/guides/day-one-sync/end-to-end-encryption-faq/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "primary",
            textDecoration: "underline",
          }}
        >
          {__("Learn More")}
        </a>
        .
      </p>
      <Button
        onClick={() => setPushedNext(true)}
        sx={{ width: "100%" }}
        variant="primary"
      >
        {__("Turn on encryption")}
      </Button>
      <Button
        sx={{ width: "100%", mt: 3, "&&": { color: "primary" } }}
        onClick={() => {
          closeModal("TURN_OFF_ENCRYPTION");
        }}
      >
        {__("Not Now")}
      </Button>
    </>
  ) : (
    <>
      <Icon
        icon="key"
        size={8}
        sx={{ transform: "rotate(135deg) scaleY(-1)" }}
      />
      <h2 sx={{ fontWeight: 700, mt: 3 }}>{__("Don't Lose Your Key")}</h2>
      <p sx={{ pt: 3, pb: [0, 8], fontSize: 1, lineHeight: 1.5, px: 3 }}>
        {__(
          "Now that end-to-end encryption is turned on, you’ll need to save your encryption key to unlock your journals on other devices.",
        )}
      </p>
      <br
        sx={{
          flexGrow: 1,
        }}
      />
      <Button
        onClick={generateMasterKey}
        sx={{ width: "100%" }}
        variant="primary"
      >
        {__("Save Encryption Key")}
      </Button>

      {!agreedToCreateKey ? (
        <Button
          sx={{ width: "100%", mt: 3, "&&": { color: "primary" } }}
          onClick={() => {
            setPushedNext(false);
          }}
        >
          {__("Go Back")}
        </Button>
      ) : (
        <Button
          sx={{ width: "100%", mt: 3, "&&": { color: "primary" } }}
          onClick={() => {
            closeModal("TURN_OFF_ENCRYPTION");
          }}
        >
          {__("Turn off encryption")}
        </Button>
      )}
    </>
  );
};
