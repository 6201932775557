import { makeAutoObservable } from "mobx";

import {
  ENABLE_ELECTRON,
  ENABLE_PASSKEYS,
  PREFILLED_ENTRY_URLS,
  READ_CONTENTS_AS_MARKDOWN,
  SHOW_ENCRYPTED_INDICATOR,
  SHOW_IMPORT_EXPORT,
  SHOW_PROFILE_SHARING_SETTING,
  SHOW_SAVE_TEMPLATE,
  SHOW_SERVER_SIDE_MOVE,
  SHOW_WP_CONNECT_FEATURE_FLAG_NAME,
  SHOW_ENTRY_COPY,
  SHOW_MEDIA_MENU,
  SHOW_LABS,
  ValidFeatureFlagKey,
} from "@/components/Settings/constants";
import {
  FeatureFlag,
  UserSettingsRepository,
} from "@/data/repositories/UserSettingsRepository";
import { isDevelopment } from "@/utils/environment";
import { isDeepEqual } from "@/utils/is-equal";

export class FeatureFlagsViewState {
  allFlags: FeatureFlag[] = [];
  failEntryTransfers = false;

  constructor(private userSettingsRepo: UserSettingsRepository) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.startSubscriptions();
  }

  startSubscriptions() {
    this.userSettingsRepo.subscribe((settings) => {
      if (settings) {
        this.gotNewFeatureFlags(settings.feature_flags);
      }
    });
  }

  public setFeatureFlag(name: ValidFeatureFlagKey, enabled: boolean) {
    this.userSettingsRepo.setFeatureFlag(name, enabled);
  }

  private gotNewFeatureFlags(featureFlags: FeatureFlag[]) {
    if (!isDeepEqual(this.allFlags, featureFlags)) {
      this.allFlags = featureFlags;
    }
  }

  private getFeatureFlag(name: ValidFeatureFlagKey) {
    const featureFlag = this.allFlags.find((f) => f.name === name);
    if (featureFlag) {
      return featureFlag.enabled;
    }
    return false;
  }

  getFlagByName(name: ValidFeatureFlagKey) {
    return this.allFlags.find((f) => f.name === name);
  }

  // For testing failed Entry transfers
  async toggleShouldFailEntryTransfers() {
    this.failEntryTransfers = !this.failEntryTransfers;
  }

  get shouldFailEntryTransfers() {
    if (!isDevelopment()) {
      return false;
    }
    return this.failEntryTransfers;
  }

  // Please make specific actions for each feature flag you want to
  // use below for the sake of clean logs and performance.
  get readContentsAsMarkdown() {
    return this.getFeatureFlag(READ_CONTENTS_AS_MARKDOWN);
  }

  get prefilledEntryURLs() {
    return this.getFeatureFlag(PREFILLED_ENTRY_URLS);
  }

  get showProfileSharingSetting() {
    return this.getFeatureFlag(SHOW_PROFILE_SHARING_SETTING);
  }

  get enablePasskeys() {
    return this.getFeatureFlag(ENABLE_PASSKEYS);
  }

  get showServerSideMove() {
    return this.getFeatureFlag(SHOW_SERVER_SIDE_MOVE);
  }

  get showSaveTemplate() {
    return this.getFeatureFlag(SHOW_SAVE_TEMPLATE);
  }

  get enableElectron() {
    return this.getFeatureFlag(ENABLE_ELECTRON);
  }

  get showImportExport() {
    return this.getFeatureFlag(SHOW_IMPORT_EXPORT);
  }

  get showEncryptedIndicator() {
    return this.getFeatureFlag(SHOW_ENCRYPTED_INDICATOR);
  }

  get showWPConnect() {
    return this.getFeatureFlag(SHOW_WP_CONNECT_FEATURE_FLAG_NAME);
  }

  get showEntryCopy() {
    return this.getFeatureFlag(SHOW_ENTRY_COPY);
  }

  get showMediaMenu() {
    return this.getFeatureFlag(SHOW_MEDIA_MENU);
  }

  get showLabs() {
    return this.getFeatureFlag(SHOW_LABS);
  }
}
