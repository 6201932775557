import { ThemeUICSSObject } from "@theme-ui/css";
import { fullscreen, info } from "@wordpress/icons";
import { useState } from "react";

import { D1Dropdown } from "@/components/D1Dropdown";
import { DraggableMediaButton } from "@/components/Editor/components/DraggableMediaButton";
import { MissingMediaInfo } from "@/components/Editor/components/FailedMedia";
import { FullscreenButton } from "@/components/SyncableImage/FullscreenButton";
import { MediaMenu } from "@/components/SyncableImage/MediaMenu";
import { ImageErrorStates } from "@/components/SyncableImage/utils";
import { MomentModel } from "@/data/models/MomentModel";
import { featureFlagsViewState } from "@/view_state/ViewStates";

const BUTTON_SIZE = 24;
const BUTTON_GAP = 8;

export const topRightButtonStyles: ThemeUICSSObject = {
  cursor: "pointer",
  backgroundColor: "black",
  borderRadius: "xs",
  height: `${BUTTON_SIZE}px`,
  opacity: "0.5",
};

export const TopRightButtonWrapper = ({
  blockClientId,
  moment,
  setIsFullscreen,
  imageErrorState,
  showButton,
  rotateLeft,
}: {
  blockClientId: string;
  moment?: MomentModel;
  setIsFullscreen?: (isFullscreen: boolean) => void;
  imageErrorState?: ImageErrorStates;
  showButton?: boolean;
  rotateLeft?: () => void;
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const getRightMarginForDraggablePopover = () => {
    let counter = 0;

    if (setIsFullscreen && imageErrorState == "NO_ERROR") {
      counter += 1;
    }

    if (imageErrorState === "LOW_RES") {
      counter += 1;
    }

    if (featureFlagsViewState.showMediaMenu) {
      counter += 1;
    }

    return counter === 0 ? counter : (BUTTON_SIZE + BUTTON_GAP) * counter;
  };

  return (
    <div
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
      }}
    >
      {(showButton || isDragging) && (
        <DraggableMediaButton
          clientId={blockClientId}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          rightMargin={getRightMarginForDraggablePopover()}
        />
      )}
      {showButton && moment && (
        <div
          sx={{ display: "flex", alignItems: "center", gap: `${BUTTON_GAP}px` }}
        >
          {imageErrorState === "NO_ERROR" && setIsFullscreen && (
            <FullscreenButton
              icon={fullscreen}
              onClick={() => setIsFullscreen(true)}
            />
          )}
          {imageErrorState === "LOW_RES" && (
            <D1Dropdown
              renderToggle={({ isOpen, onToggle }) => {
                if (showButton) {
                  return (
                    <FullscreenButton
                      icon={info}
                      onClick={onToggle}
                      aria-expanded={isOpen}
                    />
                  );
                }
              }}
              popoverProps={{ placement: "bottom-end" }}
              renderContent={() => (
                <div
                  sx={{
                    fontSize: 0,
                    lineHeight: "body",
                    width: "340px",
                    px: 3,
                    pt: 2,
                    color: "textSecondary",
                  }}
                >
                  <strong
                    sx={{
                      mb: 2,
                      display: "block",
                    }}
                  >
                    Low resolution media.
                  </strong>
                  <MissingMediaInfo moment={moment} />
                </div>
              )}
            />
          )}
          {featureFlagsViewState.showMediaMenu && (
            <MediaMenu
              journalId={moment.journalId}
              entryId={moment.entryId}
              momentId={moment.id}
              clientId={blockClientId}
              rotateLeft={rotateLeft}
            />
          )}
        </div>
      )}
    </div>
  );
};
