import { useI18n } from "@wordpress/react-i18n";
import { Reorder, useDragControls } from "framer-motion";
import { observer } from "mobx-react-lite";

import { Icon } from "@/components/Icon";
import { JournalItemView } from "@/components/Settings/Journals/JournalItemView";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { viewStates } from "@/view_state/ViewStates";

type JournalItemProps = {
  journal: JournalDBRow;
  handleReorder?: (v: Event) => void;
  handleDragStart?: (v: Event) => void;
  handleClick?: () => void;
};
export const JournalItem: React.FC<JournalItemProps> = observer(
  ({ journal, handleReorder, handleDragStart, handleClick }) => {
    const dragControls = useDragControls();
    const handleDragEnd = (v: Event) => {
      const draggingRows = document.querySelectorAll(".dragging-row");
      draggingRows.forEach((el) => {
        el.classList.remove("dragging-row");
      });

      if (handleReorder) {
        handleReorder(v);
      }
    };

    const dragStart = (v: Event) => {
      const el = v.target as Element;
      const parent = el.closest("li");
      parent?.classList.add("dragging-row");
      if (handleDragStart) {
        handleDragStart(v);
      }
    };

    const { __ } = useI18n();

    const openSettingsHandler = handleClick
      ? handleClick
      : () => {
          viewStates.modalRouter.editJournal(journal.id);
        };

    const unsyncable = viewStates.primary.isJournalUnsyncable(journal.id);
    const journalNameSuffix = unsyncable ? __("sync disabled") : "";

    return (
      <Reorder.Item
        value={journal}
        dragListener={false}
        dragControls={dragControls}
        onDragStart={dragStart}
        onDragEnd={handleDragEnd}
        sx={{
          "&&&": {
            borderBottom: "1px solid",
            borderColor: "borderPrimary",
          },
        }}
      >
        <JournalItemView
          journal={journal}
          handleClick={openSettingsHandler}
          syncable={!unsyncable}
          journalNameSuffix={journalNameSuffix}
          leftIcon={
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
                // targets touch devices
                "@media (hover: none)": {
                  // dragging doesn't currently work on touch devices
                  display: "none",
                },
              }}
              onPointerDown={(event) => dragControls.start(event)}
            >
              <Icon
                icon="drag"
                size={2}
                sx={{ color: "textPrimary", cursor: "grab" }}
              />
            </div>
          }
        />
      </Reorder.Item>
    );
  },
);
