import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";

const algo = { name: "HMAC", hash: "SHA-256" };

export const verifyHmac = async (
  symmetricKey: CryptoKey | string | ArrayBuffer | Uint8Array, // If string it would be base64 encoded
  hmacSignature: string | ArrayBuffer | Uint8Array, // If string it would be base64 encoded
  data: string | Uint8Array,
) => {
  const dataIsBase64 = typeof data === "string";
  const signatureIsBase64 = typeof hmacSignature === "string";
  const key = await makeCryptoKey(symmetricKey);

  const signature = signatureIsBase64
    ? fromBase64(hmacSignature)
    : hmacSignature;
  const dataToVerify = dataIsBase64 ? Utf8.encode(data) : data;

  const isVerified = await crypto.subtle.verify(
    algo,
    key,
    signature,
    dataToVerify,
  );

  return isVerified;
};

const makeCryptoKey = async (
  key: CryptoKey | string | Uint8Array | ArrayBuffer,
) => {
  if (key instanceof CryptoKey) {
    return key;
  }
  const keyToImport = typeof key === "string" ? fromBase64(key) : key;
  return await crypto.subtle.importKey("raw", keyToImport, algo, false, [
    "sign",
    "verify",
  ]);
};
