import { ThemeUICSSObject } from "@theme-ui/core";
import { useI18n } from "@wordpress/react-i18n";
import { PropsWithChildren, useEffect } from "react";
import { createPortal } from "react-dom";

import { ESCAPE } from "@/components/Editor/utils/keycodes";
import { Icon } from "@/components/Icon";
import { Z_INDEX_FULL_SCREEN_MEDIA } from "@/styles/theme";

type FullscreenMediaViewerProps = {
  onClose: () => void;
  setEndAnimation: (endAnimation: boolean) => void;
  endAnimation: boolean;
};

const fullscreenMediaViewerStyles: ThemeUICSSObject = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  backgroundColor: "editorFullscreenMediaViewerBg",
  zIndex: Z_INDEX_FULL_SCREEN_MEDIA,
  width: "100%",
  height: "100%",
  cursor: "zoom-out",
};

const FULLSCREEN_MEDIA_VIEWER_ID = "Fullscreen-Media-Viewer";

export const FullscreenMediaViewer: React.FC<
  PropsWithChildren<FullscreenMediaViewerProps>
> = ({ onClose, setEndAnimation, endAnimation, children }) => {
  const { __ } = useI18n();

  let portalElement = document.getElementById(FULLSCREEN_MEDIA_VIEWER_ID);
  if (!portalElement) {
    portalElement = document.createElement("div");
    portalElement.id = FULLSCREEN_MEDIA_VIEWER_ID;
  }

  const modalRoot = document.getElementById("app");
  modalRoot?.appendChild(portalElement);

  const handleEscape = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === ESCAPE) {
      setEndAnimation(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, true);
    return () => document.removeEventListener("keydown", handleEscape, true);
  }, []);

  useEffect(() => {
    if (endAnimation) {
      if (portalElement) {
        portalElement.addEventListener("animationend", handleClose);
      }
    }
    return () =>
      portalElement?.removeEventListener("animationend", handleClose);
  }, [endAnimation]);

  const handleClose = () => {
    if (portalElement) {
      modalRoot?.removeChild(portalElement);
    }
    onClose();
  };

  return createPortal(
    <div sx={fullscreenMediaViewerStyles}>
      <div
        role="button"
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
        }}
        aria-label={__("Close")}
        onClick={() => {
          setEndAnimation(true);
        }}
      >
        <Icon
          icon="close"
          size={6}
          iconStyles={{ color: "textPrimaryInverse" }}
        />
      </div>

      <div
        role="button"
        aria-label={__("Zoom out")}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          width: "100wh",
          cursor: "zoom-out",
        }}
        onClick={() => {
          setEndAnimation(true);
        }}
      >
        {children}
      </div>
    </div>,
    portalElement,
  );
};
