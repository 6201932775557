import { IconFont } from "@/components/Icon";
import { Activity, MoonPhase, WeatherCode } from "@/data/db/migrations/entry";
import { moonPhases, weatherCodes } from "@/utils/entry";

export const getActivityIconName = (activity: Activity) => {
  switch (activity) {
    case "Stationary":
      return "chair";
    case "Walking":
      return "walking";
    case "Running":
      return "running";
    case "Biking":
      return "bike";
    case "Eating":
      return "food";
    case "Automotive":
      return "car";
    case "Flying":
      return "airplane";
    case "Train":
      return "train";
    default:
      return "bike";
  }
};

export const getWeatherIconName = (code?: WeatherCode): IconFont | null => {
  if (!code) {
    return null;
  }
  if (
    code !== "rain-snow-night" &&
    code !== undefined &&
    weatherCodes.includes(code)
  ) {
    return `weather-${code}`;
  }

  return "weather-clear";
};

export const getMoonIconName = (phase?: MoonPhase): IconFont | null => {
  if (!phase) {
    return null;
  }
  if (moonPhases.includes(phase)) {
    return `moon-${phase}`;
  }

  return null;
};
