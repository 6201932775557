import { AuthToken } from "@/crypto/utils/authToken";
import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";
import { UserModel } from "@/data/models/UserModel";
import { SharedProfile, User } from "@/data/repositories/UserAPI";
import { UserRepository } from "@/data/repositories/UserRepository";
import { DailyPromptStore } from "@/data/stores/DailyPromptStore";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { AsyncJob } from "@/data/utils/AsyncJob";

export class UserStore {
  // // this stores journalOrder before it is synced to the server.
  // localJournalOrder: number[] = [];
  private pollingJob: AsyncJob | null;

  constructor(
    private userRepository: UserRepository,
    private userKeysStore: UserKeysStore,
    private dailyPromptStore: DailyPromptStore,
  ) {
    if (process.env.NODE_ENV !== "test") {
      this.pollingJob = new AsyncJob(() => this.sync(), { interval: 30000 });
    } else {
      this.pollingJob = null;
    }
  }

  async sync() {
    const user = await this.userRepository.refetchUserFromServerIfStored();
    if (user) {
      this.setActiveUser(user);
    }
  }

  async getActiveUser() {
    return this.userRepository.getActiveUser();
  }

  subscribe(callback: (user: UserModel | null) => void) {
    return this.userRepository.subscribeToUser(callback);
  }

  subscribeToAuthToken(callback: (token: AuthToken | undefined) => void) {
    return this.userRepository.subscribeToAuthToken(callback);
  }

  async getJournalOrder(): Promise<string[]> {
    const activeUser = await this.getActiveUser();
    if (!activeUser) return [];
    return [...activeUser.journal_order, ...activeUser.shared_journal_order];
  }

  async finishLogin(serverUser: User) {
    await this.userKeysStore.pull(serverUser.id);

    // Idealy this should only be done if the flag is on, but only fetching once so it's ok

    await this.dailyPromptStore.setDailyPromptSettings();
  }

  async setActiveUser(user: User) {
    await this.userRepository.saveActiveUserFromAPI(user);
  }

  async updateAvatar(avatarFile: File) {
    const uploadedId = await this.userRepository.uploadAvatar(avatarFile);

    const user = await this.getActiveUser();
    if (!user) {
      console.warn("Tried to set the avatar on a user that's not present");
      return;
    }

    if (uploadedId) {
      await this.userRepository.updateUserOnServer({
        avatar: uploadedId,
      });
    }
  }

  async updateDisplayName(displayName: string) {
    return this.userRepository.updateUserOnServer({
      display_name: displayName,
    });
  }

  async updateProfileColor(color: string) {
    return this.userRepository.updateUserOnServer({
      profile_color: color,
      avatar: "",
    });
  }

  async removeAvatar() {
    return this.userRepository.updateUserOnServer({
      avatar: "",
    });
  }

  async updateSharedProfile(sharedProfile: SharedProfile) {
    return this.userRepository.updateUserOnServer({
      shared_profile: sharedProfile,
    });
  }

  async updateInitials(initials: string) {
    return this.userRepository.updateUserOnServer({
      initials,
    });
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const result = await this.userRepository.changeUserPassword(
      currentPassword,
      newPassword,
    );
    if (result.success) {
      await this.sync();
    }
    return result;
  }

  async addPassword(newPassword: string) {
    return this.userRepository.addUserPassword(newPassword);
  }

  updateEmail({ id }: { id: string }, email: string) {
    return this.userRepository.updateUserEmail(id, email);
  }

  public async getActiveUserSubscription() {
    return this.userRepository.getSubscriptionForActiveUser();
  }

  async updateJournalOrder(personalOrder: string[], sharedOrder: string[]) {
    return this.userRepository.updateJournalOrder(personalOrder, sharedOrder);
  }

  async updateUnifiedJournalOrder(order: string[]) {
    return this.userRepository.updateUnifiedJournalOrder(order);
  }

  async fetchFeatureFlags() {
    return this.userRepository.fetchFeatureFlags();
  }

  async getQRLoginSecret(nonce: string) {
    return this.userRepository.getQRLoginSecret(nonce);
  }

  startPolling() {
    this.pollingJob?.start();
  }

  stopPolling() {
    this.pollingJob?.stop();
  }

  restartPolling() {
    this.pollingJob?.restart();
  }

  subscribeToLabFeatures(callback: (flags: ServerFlagDBRow[]) => void) {
    return this.userRepository.subscribeToLabFeatures(callback);
  }
}
