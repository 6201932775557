import { ThemeUICSSObject } from "@theme-ui/css";
import { Button } from "@wordpress/components";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Icon, IconFont } from "@/components/Icon";

interface Props {
  icon: IconFont | null;
  text: string;
  name?: string;
  onClick?: () => void;
  onClear?: () => void;
  isActive?: boolean;
  fullWidth?: boolean;
  hideChevron?: boolean;
  filterCounter?: number;
  styles?: ThemeUICSSObject;
}

export const Pill: React.FC<Props> = ({
  icon,
  text,
  name,
  onClick,
  onClear,
  isActive = false,
  fullWidth = false,
  hideChevron = false,
  filterCounter,
  styles,
}) => {
  const color = isActive ? "white" : "textPrimary";
  const showClearFilter = isActive && !fullWidth && onClear;

  return (
    <Button
      className={`filter-pill ${isActive ? "active" : ""}`}
      sx={{
        height: "30px",
        bg: isActive && !fullWidth ? "mainToolbarBg" : "surface_light1_dark5",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        gap: 1,
        fontSize: 0,
        "&&&&.components-button": {
          color,
          borderRadius: 5,
          px: 2.5,
        },
        "& span": {
          whiteSpace: "nowrap",
          color: isActive && !fullWidth ? "textOnColor" : "textPrimary",
        },
        svg: {
          fill: isActive ? "textOnColor" : "textPrimary",
          width: "16px",
          height: "16px",
        },
        ...(showClearFilter && {
          "&.active:hover": {
            "& .clearFilter": {
              display: "contents",
            },
            "& .filterIcon": {
              display: "none",
            },
          },
        }),
        ...(fullWidth && {
          justifyContent: "space-between",
          height: "32px",

          "&&&&.components-button": {
            width: "100%",
            borderRadius: 2,
          },
        }),
        ...styles,
      }}
      iconSize={16}
      onClick={onClick}
    >
      {icon && (
        <span className="filterIcon" sx={{ display: "contents" }}>
          <Icon icon={icon} size={2} />
        </span>
      )}
      {showClearFilter && (
        <span
          className="clearFilter"
          sx={{
            display: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            analytics.tracks.recordEvent(EVENT.searchFilterRemoved, {
              filter_type: name ?? "unknown",
            });
            onClear();
          }}
        >
          <Icon icon="close-circle-filled" size={4} />
        </span>
      )}
      <span>{text}</span>
      {isActive && filterCounter && (
        <div
          sx={{
            border: "1px solid",
            borderColor: "surface_light1_dark5",
            borderRadius: 2,
            color: "textPrimary",
            bg: "surface_light1_dark5",
            height: "16px",
            minWidth: "16px",
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: "3px",
            py: 0.5,
          }}
        >
          {filterCounter}
        </div>
      )}
      {!hideChevron && !filterCounter && (
        <Icon icon="chevron-down-small" size={2} />
      )}
    </Button>
  );
};
