import { ThemeUICSSObject } from "@theme-ui/css";
import { Button, ToolbarButton } from "@wordpress/components";
import { ToolbarButtonProps } from "@wordpress/components/build-types/toolbar/toolbar-button/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

import { journalColorValues } from "@/styles/JournalColorValues";

const buttonStyle: ThemeUICSSObject = {
  "&&&:first-of-type > .components-toolbar-button": {
    borderRadius: "6px 0 0 6px",
    paddingLeft: 1,
  },
  "&&& > .components-toolbar-button": {
    borderRadius: 0,
    padding: 0,
    height: 5,
    width: 5,
    minWidth: 5,
    backgroundColor: "contextToolbarBtnBg",
    color: "white",
    "&:hover": {
      backgroundColor: "surfaceHover",
      svg: {
        color: "white",
        path: {
          fill: "white",
        },
      },
    },
    "&.is-pressed": {
      backgroundColor: "contextToolbarBgActive",
      "::before": {
        background: "none",
      },
    },
    "&.components-button:focus::before": {
      boxShadow: "none",
    },

    "&.highlight-button": {
      color: journalColorValues.honey,
      borderRadius: "xs",
    },
  },
};

export const ContextToolbarButton: React.FC<
  WordPressComponentProps<ToolbarButtonProps, typeof Button, false>
> = (props) => {
  return (
    <div sx={buttonStyle}>
      <ToolbarButton iconSize={18} {...props} disabled={props.disabled} />
    </div>
  );
};
