import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import { BackupButton } from "./BackupButton";
import { GoogleDriveBackupButton } from "./GoogleDriveBackupButton";
import { generatePDF } from "./generatePDF";

import { Icon } from "@/components/Icon";
import { CloudKitBackupButton } from "@/components/Settings/Account/MasterKeyModal/CloudKitBackupButton";
import { MasterKey } from "@/crypto/types/MasterKey";
import { LocationOfKey } from "@/data/repositories/UserKeysRepository";
import { surfaceColors } from "@/styles/SurfaceValues";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const MasterKeyToQRCode = observer(
  ({
    masterKey,
    onSave,
  }: {
    masterKey: MasterKey;
    onSave: (locationOfKey: LocationOfKey) => void;
  }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const { __ } = useI18n();
    useEffect(() => {
      const generateQRCode = async () => {
        const QRCode = await import("qrcode");
        const canvas = canvasRef.current;

        QRCode.toCanvas(
          canvas,
          masterKey.url,
          { errorCorrectionLevel: "H", margin: 0, width: 200 },
          (error) => {
            if (error) {
              console.error("Failed to generate QR code:", error);
            }
          },
        );
      };

      generateQRCode();
    }, []);

    const savePDFClick = async () => {
      await generatePDF(masterKey);
      onSave("pdfDownloadWeb");
      viewStates.snackbar.newMessage(__("Downloaded Master Key PDF"));
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(masterKey.wholeString);
      viewStates.snackbar.newMessage(__("Copied encryption key to clipboard"));
    };

    return (
      <>
        <div
          sx={{
            borderRadius: "md",
            border: "4px solid",
            borderColor: "primary",
            mb: 4,
            mx: "auto",
            mt: 2,
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          <h2
            sx={{
              backgroundColor: "primary",
              color: "textOnColor",
              textAlign: "center",
              fontSize: 0,
              letterSpacing: "sm",
              fontFamily: "monospace",
              fontWeight: 400,
              py: 1,
              px: 5,
              mb: 3,

              // remove this almost invisible space at the top
              mt: "-1px",
            }}
          >
            DAY ONE ENCRYPTION KEY
          </h2>

          <div
            sx={{
              position: "relative",
            }}
          >
            <img
              src="/assets/qr-key.png"
              alt="Day One Key"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50px",
                height: "50px",
              }}
            />
            <canvas ref={canvasRef} />
          </div>
          <Button
            sx={{
              width: "18em",
              borderRadius: "xs",
              p: 1,
              mb: 1,
              fontFamily: "monospace",
              fontSize: 0,
              resize: "none",
              textAlign: "center",
              backgroundColor: "transparent",
              lineHeight: "1.3",
              transition:
                "background-color 0.2s ease-in-out, transform 0.2s ease-in-out",
              "&&": {
                color: "#000",
              },
              "& > svg": {
                fill: "textPrimary",
                flexShrink: 0,
              },
              "&:hover": {
                backgroundColor: surfaceColors.lightHover,
              },
              "&:active": {
                transform: "scale(0.95)",
              },
            }}
            onClick={copyToClipboard}
            label={__("Copy encryption key to clipboard")}
            tooltipPosition={"bottom center"}
            showTooltip
          >
            {masterKey.wholeString}
          </Button>
        </div>
        <div
          sx={{
            "&& > button": {
              borderRadius: "0",
              borderColor: "var(--theme-ui-colors-borderPrimary) !important",
            },
            "&& :first-of-type": {
              borderTopLeftRadius: "md",
              borderTopRightRadius: "md",
            },
            "&& :last-of-type": {
              borderBottomLeftRadius: "md",
              borderBottomRightRadius: "md",
            },
          }}
        >
          <GoogleDriveBackupButton
            onSave={() => onSave("drive")}
            masterKeyString={masterKey.wholeString}
          />

          <CloudKitBackupButton
            masterKeyString={masterKey.wholeString}
            onSave={() => onSave("cloudkit")}
          />

          <BackupButton
            position="bottom"
            onClick={savePDFClick}
            backedUp={primaryViewState.user?.master_key_storage?.includes(
              "pdfDownloadWeb",
            )}
            icon={<Icon icon="save" />}
          >
            {__("Download PDF")}
          </BackupButton>
        </div>
      </>
    );
  },
);
