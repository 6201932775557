import { keyframes } from "@emotion/react";
import { useBlockProps } from "@wordpress/block-editor";
import { image } from "@wordpress/icons";
import { CSSProperties } from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { ImageBlockEditProps } from "@/components/Editor/blocks/constants";
import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { UploadingMediaIcon } from "@/components/Editor/components/UploadingMediaIcon";
import { useMediaUpload } from "@/components/Editor/hooks/mediaUpload";
import { FullscreenMediaViewer } from "@/components/FullscreenMediaViewer";
import { FullImage } from "@/components/SyncableImage/FullImage";
import { TopRightButtonWrapper } from "@/components/SyncableImage/TopRightButton";
import {
  ImageErrorStates,
  calculateDimensions,
} from "@/components/SyncableImage/utils";
import { useMoment } from "@/data/hooks/moments";
import { useElementSize } from "@/hooks/useElementSize";
import { dayOneBlue } from "@/styles/theme";
import { rotateCounterClockwise } from "@/utils/file-helper";
import { getMediaWidth } from "@/utils/gallery";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type BlobImageProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  galleryWidth?: number;
  galleryHeight?: number;
  isSelected: boolean;
  blockClientId: string;
  setAttributes: ImageBlockEditProps["setAttributes"];
};

export const SyncableImage: React.FC<BlobImageProps> = ({
  journalId,
  entryId,
  clientId,
  aspectRatio,
  galleryHeight,
  isSelected,
  blockClientId,
  setAttributes,
}) => {
  const { blob, thumbnailBlob, moment, isLoading } = useMoment(
    journalId,
    entryId,
    clientId,
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [objectURL, setObjectURL] = useState<string>("");
  const [imageErrorState, setImageErrorState] =
    useState<ImageErrorStates>("NO_ERROR");

  const blockProps = useBlockProps({
    style: {
      position: "relative",
      border: "none",
    } as CSSProperties,
  });
  const imageRef = useRef<HTMLImageElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const { height: viewPortHeight, width: viewPortWidth } =
    useElementSize("main");
  const isInGallery = !!(aspectRatio && galleryHeight);

  const globalEntryID = primaryViewState.selectedGlobalEntryID;
  const entryJournal =
    primaryViewState.selectedJournal ??
    primaryViewState.getJournalById(globalEntryID?.journal_id);

  const { updateEditMedia } = useMediaUpload(
    globalEntryID,
    viewStates,
    entryJournal?.is_shared || false,
  );

  const rotateLeft = async () => {
    if (!moment) {
      return;
    }
    const editedImage = await rotateCounterClockwise(objectURL, moment);

    if (!isInGallery) {
      updateEditMedia(editedImage, moment.id, setAttributes);
    } else {
      updateEditMedia(editedImage, moment.id, undefined, blockClientId);
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      setIsFullscreen(false);
    }
  }, [viewPortHeight, viewPortWidth]);

  useEffect(() => {
    let done = false;
    let url = "";

    if (!done) {
      if (blob) {
        setImageErrorState("NO_ERROR");
        url = URL.createObjectURL(blob);
        done = true;
      } else if (thumbnailBlob) {
        setImageErrorState("LOW_RES");
        url = URL.createObjectURL(thumbnailBlob);
      } else {
        setImageErrorState("NOT_FOUND");
      }

      if (url != "" && url != null) {
        setObjectURL(url);
      }
    }
    return () => {
      done = true;
      URL.revokeObjectURL(url);
    };
  }, [blob, thumbnailBlob]);

  useLayoutEffect(() => {
    if (isFullscreen && imageRef.current) {
      const { x, y, width, height } = imageRef.current.getBoundingClientRect();
      setCoords({ x, y, width, height });
    }
  }, [isFullscreen]);

  const [endAnimation, setEndAnimation] = useState(false);

  // no objectURL yet
  if (isLoading) {
    if (isInGallery) {
      return (
        <LoadingMediaPlaceholder
          styles={{
            flexBasis: 0,
            flexGrow: aspectRatio,
            aspectRatio,
            height: galleryHeight,
            my: 0,
          }}
        />
      );
    }

    if (moment) {
      return (
        <LoadingMediaPlaceholder
          key={moment.id}
          height={moment.height || undefined}
          width={moment.width || undefined}
        />
      );
    }

    return <LoadingMediaPlaceholder />;
  }

  if (moment && objectURL) {
    const width = isInGallery
      ? `${getMediaWidth(aspectRatio, galleryHeight)}px`
      : undefined;

    const height = isInGallery ? `${galleryHeight}px` : "auto";

    const imageHTML = (
      <div
        {...blockProps}
        data-testid="inline-image"
        data-momentid={moment.id}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        sx={{
          position: "relative",
          display: "inline-block",
          width,
          height,
          boxShadow: isInGallery
            ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
            : undefined,
          "& img": {
            p: "1px",
            borderRadius: isInGallery ? undefined : "3px",
            border: isInGallery ? undefined : "3px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            width,
            height,
          },
        }}
      >
        {imageErrorState === "NOT_FOUND" ? (
          <div>
            <FailedMedia icon={image} moment={moment} />
          </div>
        ) : (
          <FullImage
            src={objectURL}
            height={moment.height || undefined}
            width={moment.width || undefined}
            ref={imageRef}
          />
        )}

        <UploadingMediaIcon moment={moment}></UploadingMediaIcon>
        <TopRightButtonWrapper
          blockClientId={blockClientId}
          moment={moment}
          setIsFullscreen={setIsFullscreen}
          imageErrorState={imageErrorState}
          showButton={isHovered}
          rotateLeft={rotateLeft}
        />
      </div>
    );
    if (isFullscreen) {
      const imageWidth =
        moment.width === 0
          ? moment.thumbnail.width || window.innerWidth
          : moment.width;
      const imageHeight =
        moment.height === 0
          ? moment.thumbnail.height || window.innerHeight
          : moment.height;
      const { width: finalWidth, height: finalHeight } = calculateDimensions(
        imageWidth,
        imageHeight,
        window.innerWidth,
        window.innerHeight,
      );

      const animationInitial = `{
        transform: translate(calc(-50vw + ${coords.x + coords.width / 2}px), calc(-50vh + ${coords.y + coords.height / 2}px)) scale(${(coords.width / finalWidth, coords.height / finalHeight)});
      }`;

      const animationFinal = `{
        left: "50%",
        top: "50%",
        transform: translate(-50%, -50%) scale(1, 1);
      }`;

      const zoomIn = keyframes`from ${animationInitial} to ${animationFinal}`;
      const zoomOut = keyframes`from ${animationFinal} to ${animationInitial}`;

      return (
        <>
          {imageHTML}
          <FullscreenMediaViewer
            onClose={() => {
              setIsFullscreen(false);
              setEndAnimation(false);
            }}
            setEndAnimation={setEndAnimation}
            endAnimation={endAnimation}
          >
            <div
              sx={{
                height: finalHeight,
                width: finalWidth,
                overflow: "hidden",
                position: "absolute",
                animation: endAnimation
                  ? `${zoomOut} 0.3s ease-in-out`
                  : `${zoomIn} 0.3s ease-in-out`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                sx={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                src={objectURL}
              />
            </div>
          </FullscreenMediaViewer>
        </>
      );
    }

    return imageHTML;
  }

  if (isInGallery) {
    return (
      <div {...blockProps}>
        <FailedMedia moment={moment} icon={image} />
      </div>
    );
  }

  return <FailedMedia moment={moment} icon={image} />;
};
