import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Icon } from "@/components/Icon";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const NoEncryptionInfo: React.FC = observer(() => {
  const { __ } = useI18n();
  if (primaryViewState.userEncryptionKeyExists) {
    return <AddKey />;
  }

  return (
    <>
      <p sx={{ fontSize: 1, my: 2 }}>
        {__(
          "To create your own templates you must first have a encryption key.",
        )}
      </p>
      <p sx={{ fontSize: 1, my: 2 }}>
        <a
          sx={{
            color: "primary",
            textDecoration: "underline",
          }}
          href="https://dayoneapp.com/guides/day-one-sync/end-to-end-encryption-faq/"
          target="_blank"
          rel="noopener"
        >
          {__("Learn more about our end-to-end encryption.")}
        </a>{" "}
      </p>
      <Button
        sx={{ mt: 2 }}
        key="add-key"
        variant="primary"
        onClick={() =>
          viewStates.modalRouter.showKeyModal({
            openedFrom: "template_management",
            isCreatingSharedJournal: false,
          })
        }
        label={__("Turn on encryption")}
      >
        <Icon icon="lock-filled" size={3} /> {__("Turn on encryption")}
      </Button>
    </>
  );
});

const AddKey: React.FC = () => {
  const { __ } = useI18n();
  return (
    <>
      <p sx={{ fontSize: 1, my: 2 }}>
        {__(
          "To create your own templates you must first enter a encryption key.",
        )}
      </p>
      <p sx={{ fontSize: 1, my: 2 }}>
        <a
          sx={{
            color: "primary",
            textDecoration: "underline",
          }}
          href="https://dayoneapp.com/guides/day-one-on-the-web/getting-started-on-the-web/#adding-your-encryption-key"
          target="_blank"
          rel="noopener"
        >
          {__("For more information on adding your key see our support guide.")}
        </a>{" "}
      </p>
      <Button
        sx={{ mt: 2 }}
        key="add-key"
        variant="primary"
        onClick={() => {
          viewStates.modalRouter.showKeyModal({
            openedFrom: "template_management",
            isCreatingSharedJournal: false,
          });
        }}
      >
        <Icon icon="lock-filled" size={3} />
        {__("Add Encryption Key")}
      </Button>
    </>
  );
};
