export const SHOW_WP_CONNECT_FEATURE_FLAG_NAME = "showWPConnect";

export const READ_CONTENTS_AS_MARKDOWN = "readContentsAsMarkdown";
export const PREFILLED_ENTRY_URLS = "prefilledEntryURLs";
export const ENABLE_PASSKEYS = "enablePasskeys";
export const SHOW_PROFILE_SHARING_SETTING = "showProfileSharingSetting";
export const SHOW_SERVER_SIDE_MOVE = "showServerSideMove";
export const SHOW_ENTRY_COPY = "showEntryCopy";
export const ENABLE_ELECTRON = "enableElectron";
export const SHOW_SAVE_TEMPLATE = "showSaveTemplate";
export const SHOW_IMPORT_EXPORT = "showImportExport";
export const SHOW_ENCRYPTED_INDICATOR = "showEncryptedIndicator";
export const SHOW_MEDIA_MENU = "showMediaMenu";
export const SHOW_LABS = "showLabs";

export const FEATURE_FLAG_NAMES = [
  READ_CONTENTS_AS_MARKDOWN,
  PREFILLED_ENTRY_URLS,
  ENABLE_PASSKEYS,
  SHOW_PROFILE_SHARING_SETTING,
  SHOW_SERVER_SIDE_MOVE,
  SHOW_ENTRY_COPY,
  ENABLE_ELECTRON,
  SHOW_SAVE_TEMPLATE,
  SHOW_IMPORT_EXPORT,
  SHOW_ENCRYPTED_INDICATOR,
  SHOW_WP_CONNECT_FEATURE_FLAG_NAME,
  SHOW_MEDIA_MENU,
  SHOW_LABS,
] as const;

export type ValidFeatureFlagKey = (typeof FEATURE_FLAG_NAMES)[number];
