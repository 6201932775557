import { MoonPhase } from "@/data/db/migrations/entry";
import { i18n } from "@/utils/i18n";

export const getMoonPhaseText = (phase?: number, phaseCode?: MoonPhase) => {
  if (!phase || !phaseCode) {
    return "";
  }
  let phaseText = "";
  switch (phaseCode) {
    case "new":
      phaseText = i18n.__("New Moon");
      break;
    case "full":
      phaseText = i18n.__("Full Moon");
      break;
    case "waxing-crescent":
      phaseText = i18n.__("Waxing Crescent Moon");
      break;
    case "first-quarter":
      phaseText = i18n.__("First Quarter Moon");
      break;
    case "last-quarter":
      phaseText = i18n.__("Last Quarter Moon");
      break;
    case "waning-crescent":
      phaseText = i18n.__("Waning Crescent Moon");
      break;
    case "waning-gibbous":
      phaseText = i18n.__("Waning Gibbous Moon");
      break;
    case "waxing-gibbous":
      phaseText = i18n.__("Waxing Gibbous Moon");
      break;
  }
  return `${phase}, ${phaseText}`;
};
