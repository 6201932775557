import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { ShareModal } from "@/components/Editor/components/ShareModal";

export const ShareButton: React.FC = observer(() => {
  const { __ } = useI18n();
  const [showDialogue, setShowDialogue] = useState(false);

  const onClose = () => {
    setShowDialogue(false);
  };

  const onOpen = () => {
    setShowDialogue(true);
  };

  return (
    <div>
      <FixedToolbarButton
        onClick={onOpen}
        title={__("Share Prefilled Link")}
        iconName="share"
      />
      {showDialogue && <ShareModal onClose={onClose} />}
    </div>
  );
});

ShareButton.displayName = "ShareButton";
