import { Button, Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { StarRating } from "@/components/PremiumUpgrade/Reviews";
import { APP_DOWNLOAD_LINKS } from "@/components/constants";
import { DayOneLogo } from "@/components/icons/DayOneLogo";
import { localStorageWrapper } from "@/data/db/DOLocalStorage";
import { getClientMeta } from "@/data/utils/clientMeta";

export const TopBanner = () => {
  const { __ } = useI18n();
  const [hideBanner, setHideBanner] = useState(
    localStorageWrapper.get("hide_app_download_banner") ?? false,
  );
  const [loading, setLoading] = useState(true);
  const [app_download_url, setAppDownloadUrl] = useState<string>("");

  useEffect(() => {
    getClientMeta().then((meta) => {
      const isMobileDevice =
        meta.creationDevice === "Mobile" || meta.creationDevice === "Tablet";
      const isIOS = ["iPhone", "iPad"].some((test) =>
        meta.creationOSName?.includes(test),
      );
      if (
        // Hide the banner on Desktop devices and Safari on iOS because we have the native app download banner
        !isMobileDevice ||
        (isIOS && meta.browserName === "Safari")
      ) {
        setHideBanner(true);
      } else {
        if (meta.creationOSName?.toLocaleLowerCase() === "android") {
          setAppDownloadUrl(APP_DOWNLOAD_LINKS.google);
        } else if (isIOS) {
          setAppDownloadUrl(APP_DOWNLOAD_LINKS.ios);
        }
      }

      setLoading(false);
    });
  }, []);

  if (loading || hideBanner) return null;

  return (
    <Flex sx={{ background: "surface_light1_dark1", p: 3 }}>
      <Button
        onClick={() => {
          localStorageWrapper.set("hide_app_download_banner", true);
          setHideBanner(true);
        }}
        size="small"
        sx={{
          "&.components-button.has-icon": {
            color: "textTertiary",
          },
        }}
      />
      <div
        sx={{
          height: "61px",
          width: "61px",
          "& > svg": {
            flexGrow: 1,
            height: "100%",
            width: "100%",
          },
        }}
      >
        <DayOneLogo />
      </div>
      <Flex direction="column" sx={{ ml: 2 }}>
        <p sx={{ fontWeight: "medium", fontSize: 2 }}>Day One Journal</p>
        <p sx={{ color: "textSecondary", fontSize: 0 }}>
          {__("Gratitude, travel, daily notes")}
        </p>
        <StarRating fillColor="#EE9636" />
      </Flex>
      <Button
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.dowloadAppTopBannerClicked);
        }}
        variant="primary"
        sx={{
          flexGrow: 0,
          width: 78,
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: 3,
          lineHeight: "heading",
          letterSpacing: 1,
          ml: "auto",
          "&.components-button.is-primary": {
            backgroundColor: "#007AFF",
            color: "white",
            borderRadius: 5,
          },
        }}
      >
        <a href={app_download_url} target="_blank">
          {__("Get")}
        </a>
      </Button>
    </Flex>
  );
};
