import { RichTextShortcut } from "@wordpress/block-editor";
import {
  ExtendedFormatConfiguration,
  registerFormatType,
  toggleFormat,
} from "@wordpress/rich-text";

import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { FormatToolbarButton } from "@/components/Editor/components/FormatToolbarButton";
import {
  formatIsRegistered,
  matchFormattingTags,
} from "@/components/Editor/formats/utils";
import { Icon } from "@/components/Icon";

const NAME = "dayone/bold";
const title = "Bold";
const tagName = "strong";
const TAGS = ["**", "__"];

export const bold: ExtendedFormatConfiguration = {
  title,
  tagName,
  className: null,
  __unstableInputRule: (value) => matchFormattingTags(value, TAGS, NAME),
  edit({ isActive, value, onChange, onFocus }) {
    function onClick() {
      onChange(toggleFormat(value, { type: NAME }));
      onFocus();
    }

    return (
      <>
        <RichTextShortcut type="primary" character="b" onUse={onClick} />
        <FormatToolbarButton
          icon={
            <Icon icon="bold" size={4} iconStyles={{ fontWeight: "bold" }} />
          }
          title={title}
          onClick={onClick}
          isActive={isActive}
        />
      </>
    );
  },
  inactive() {
    return (
      <FixedToolbarButton
        icon={<Icon icon="bold" size={4} iconStyles={{ fontWeight: "bold" }} />}
        title={title}
        disabled
      />
    );
  },
};

export const register = () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, bold);
  }
};
