import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Icon } from "@/components/Icon";
import { SharedJournalMembers } from "@/components/JournalSettings/SharedJournalMembers";
import {
  modalRouterViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

interface Props {
  journalId: string;
  goBackAction?: () => void;
}

export const SharedJournalSettings: React.FC<Props> = observer(
  ({ journalId, goBackAction }) => {
    const { __ } = useI18n();
    const journal = primaryViewState.journals.find((j) => j.id === journalId);

    if (!journal || !journal.is_shared) return null;

    return (
      <div>
        {goBackAction && (
          <Button
            sx={{ mt: -2, mb: 2 }}
            variant="tertiary"
            onClick={goBackAction}
          >
            <Icon icon="chevron-left-small" />
            {__("Back")}
          </Button>
        )}
        <SharedJournalMembers
          participants={journal.participants}
          journalId={journal.id}
          handleCreateInvite={() =>
            modalRouterViewState.showSharedJournalInvite(journal.id)
          }
        />
      </div>
    );
  },
);

SharedJournalSettings.displayName = "SharedJournalSettings";
