import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { Icon } from "@/components/Icon";
import { Item, ItemGroup } from "@/components/ItemGroup";

export const StorageDetails: React.FC = () => {
  const [storageInfo, setStorageInfo] = useState<null | StorageEstimate>(null);
  const { __ } = useI18n();
  useEffect(() => {
    if (navigator?.storage?.estimate) {
      navigator.storage.estimate().then((info) => {
        setStorageInfo(info);
      });
    }
  }, []);

  if (storageInfo === null) {
    return null;
  }

  return (
    <ItemGroup addMargin={true}>
      <Item>
        <Flex>
          <p>
            You are using {((storageInfo.usage || 0) / 1e9).toFixed(2)} GB out
            of {((storageInfo.quota || 0) / 1e9).toFixed(2)} GB
          </p>

          <span title={__("The maximum is a limit set by the Browser")}>
            <Icon
              icon="info-circle"
              sx={{ color: "textSecondary", display: "inline-block" }}
            />
          </span>
        </Flex>
      </Item>
    </ItemGroup>
  );
};
