import { ThemeUICSSObject } from "@theme-ui/css";
import { Flex, FlexBlock } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { Icon } from "@/components/Icon";

const REVIEWS_TEXT = [
  "I've been using this app since 2010 and I have been loving it. I'm so happy this app exists and it's worth all your money",
  "Well worth the money! I've been using Day One for over eight months, and I am still loving it! It has been a HUGE game changer for me. I have recommended Day One to many of my friends and colleagues. For me, it is much for than just a journaling tool",
  "Works well. beautiful interface. Memories and thoughts documented are worth more than any amount of money",
  "Hands down worth every cent for premium! Personally I am not one to spend money on apps but this one is worth it",
  "I am not the journaling type but this app has converted me. Making the process frictionless and letting me look back at older times in my life has improved my mental health and helped build perspective. I would highly recommend this app to anyone",
  "This is truly a great App. Simple to use and very effective in terms of maintaining your details. Never come across such a good app. Indeed premium is the best option. worth paying a small amount. Go for it...",
  "Fantastic app. Worth every penny, I am finally journaling again!",
];

export const Reviews: React.FC = () => {
  const { __ } = useI18n();

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={3}
      sx={{ mt: 3.5 }}
    >
      <p sx={{ fontSize: 0, fontWeight: "medium", textAlign: "center" }}>
        {__("4.8 rating on the App Store / 4.5 rating on the Play Store")}
      </p>
      <StarRating wrapperStyles={{ justifyContent: "center" }} />
      {REVIEWS_TEXT.map((reviewText, index) => (
        <ReviewItem key={index} reviewText={reviewText} />
      ))}
    </Flex>
  );
};

export const ReviewItem: React.FC<{ reviewText: string }> = ({
  reviewText,
}) => {
  return (
    <FlexBlock
      sx={{
        bg: "surface_light2_dark2",
        p: 4,
        borderRadius: "lg",
      }}
    >
      <p sx={{ fontSize: 1, lineHeight: 2, mb: 2 }}>"{reviewText}"</p>
      <StarRating size={0} wrapperStyles={{ justifyContent: "flex-start" }} />
    </FlexBlock>
  );
};

export const StarRating: React.FC<{
  starCount?: number;
  size?: number;
  fillColor?: string;
  wrapperStyles?: ThemeUICSSObject;
}> = ({ starCount = 5, size = 3, fillColor = "#FFC107", wrapperStyles }) => {
  return (
    <Flex gap={1} justify="flex-start" sx={{ ...wrapperStyles }}>
      {[...Array(starCount)].map((_, index) => (
        <Icon
          key={index}
          icon="star-filled"
          size={size}
          sx={{ color: fillColor }}
        />
      ))}
    </Flex>
  );
};
