import {
  Flex,
  FlexItem,
  __experimentalText as Text,
} from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Icon } from "@/components/Icon";
import { ItemButton } from "@/components/ItemGroup";
import { JournalBadge } from "@/components/JournalSidebar/JournalBadge";
import { SharedInfo } from "@/components/Settings/Journals/SharedInfo";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { featureFlagsViewState } from "@/view_state/ViewStates";

type JournalItemViewProps = {
  journal: JournalDBRow;
  handleClick: () => void;
  syncable: boolean;
  leftIcon?: React.ReactNode;
  journalNameSuffix?: string;
};

export const JournalItemView: React.FC<JournalItemViewProps> = observer(
  ({ journal, handleClick, syncable, leftIcon, journalNameSuffix }) => {
    const { __ } = useI18n();
    const showEncryptedIndicator =
      !!journal.e2e && featureFlagsViewState.showEncryptedIndicator;
    return (
      <Flex
        sx={{
          position: "relative",
          ":hover": {
            bg: "surfaceHover",
            "& .drag-icon": {
              display: "flex",
            },
          },
          ":active": {
            "& .drag-icon": {
              display: "flex",
            },
            bg: "surfaceActive",
          },
        }}
      >
        <Flex
          className="drag-icon"
          sx={{
            width: 3,
            height: 5,
            ml: 1,
            display: "none",
            position: "absolute",
            left: 0,
          }}
          align="center"
          justify="center"
        >
          {leftIcon}
        </Flex>
        <ItemButton
          sx={{
            "&&": {
              borderRadius: "0",
              textAlign: "left",
              pr: 0,
              pl: 3.5,
              height: "48px",
              "&:hover": {
                bg: "surfaceHover",
                color: "inherit",
              },
            },
          }}
          onClick={handleClick}
          label={sprintf(`${__("Edit")} %s`, journal.name)}
        >
          <Flex
            sx={{
              opacity: syncable ? 1 : 0.7,
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              userSelect: "none",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <JournalBadge
              color={journal.color}
              orderedByEditDate={journal.sort_method === "editDate"}
            />
            <Text
              className="journal-name"
              truncate
              sx={{
                textWrap: "nowrap",
                ml: 2,
                color: "textSecondary",
                flexGrow: 1,
                cursor: "pointer",
                fontStyle: syncable ? undefined : "italic",
              }}
            >
              {journal.name +
                (journalNameSuffix ? ` ${journalNameSuffix}` : "")}
            </Text>
            {showEncryptedIndicator && (
              <Icon
                icon="lock-filled"
                size={0}
                sx={{ flexShrink: 0, color: "textSecondary" }}
              />
            )}
            {journal.is_shared && <SharedInfo journal={journal} />}
            <FlexItem sx={{ pr: 4, mt: 1, flexShrink: 0 }}>
              <Icon icon="chevron-right-small" />
            </FlexItem>
          </Flex>
        </ItemButton>
      </Flex>
    );
  },
);
