import { ThemeUICSSObject } from "@theme-ui/css";
import { Button, ToolbarButton } from "@wordpress/components";
import { ToolbarButtonProps } from "@wordpress/components/build-types/toolbar/toolbar-button/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

import { Icon, IconFont } from "@/components/Icon";

const buttonStyle: ThemeUICSSObject = {
  marginRight: 2,
  "&&& > .components-toolbar-button": {
    padding: "1",
    height: 5,
    width: "auto",
    minWidth: 5,
    borderRadius: "sm",
    zIndex: "unset",
    "&.is-pressed": {
      bg: "mainToolbarBg",
      color: "textOnColor",
    },
    "&.components-button:hover": {
      bg: "surface_light3_dark3",
      color: "inherit",
    },
    "&.components-button::before": {
      background: "none",
    },
    "&.components-button:focus::before": {
      boxShadow: "none",
    },

    "&[aria-disabled=true]": {
      pointerEvents: "none",
      color: "buttonTextDisabled",
    },
  },
};

type Props = WordPressComponentProps<
  ToolbarButtonProps,
  typeof Button,
  false
> & {
  iconName?: IconFont;
};

export const FixedToolbarButton: React.FC<Props> = ({ iconName, ...props }) => {
  let icon = null;
  if (iconName) {
    icon = <Icon icon={iconName} />;
  } else {
    icon = props.icon;
  }

  return (
    <div sx={buttonStyle}>
      <ToolbarButton {...props} icon={icon} disabled={props.disabled} />
    </div>
  );
};
