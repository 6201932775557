import { RichTextShortcut } from "@wordpress/block-editor";
import {
  ExtendedFormatConfiguration,
  registerFormatType,
  toggleFormat,
} from "@wordpress/rich-text";

import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { FormatToolbarButton } from "@/components/Editor/components/FormatToolbarButton";
import {
  formatIsRegistered,
  matchFormattingTags,
} from "@/components/Editor/formats/utils";
import { Icon } from "@/components/Icon";

export const NAME = "dayone/italic";
const title = "Italic";
const tagName = "em";
const TAGS = ["*", "_"];

export const italic: ExtendedFormatConfiguration = {
  title,
  tagName,
  className: null,
  __unstableInputRule: (value) => matchFormattingTags(value, TAGS, NAME),
  edit({ isActive, value, onChange, onFocus }) {
    function onClick() {
      onChange(toggleFormat(value, { type: NAME }));
      onFocus();
    }
    return (
      <>
        <RichTextShortcut type="primary" character="i" onUse={onClick} />
        <FormatToolbarButton
          icon={<Icon icon="italic" size={4} />}
          title={title}
          onClick={onClick}
          isActive={isActive}
        />
      </>
    );
  },
  inactive: () => {
    return (
      <FixedToolbarButton
        icon={<Icon icon="italic" size={4} />}
        title={title}
        disabled
      />
    );
  },
};

export const register = () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, italic);
  }
};
