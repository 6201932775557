import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  AUTH_TOKEN_EXPIRY_TIME,
  AuthToken,
  deserializeAuthToken,
} from "@/crypto/utils/authToken";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";
import { UserDBRow } from "@/data/db/migrations/user";
import { UserModel } from "@/data/models/UserModel";
import { JournalParticipantRepository } from "@/data/repositories/JournalParticipantRepository";
import { User, UpdateServerUser } from "@/data/repositories/UserAPI";
import { SubscriptionInfo } from "@/data/repositories/V2API";
import { isDeepEqual } from "@/utils/is-equal";

const maybeMakeModel = (
  user: UserDBRow | null | undefined,
): UserModel | null => {
  return user ? new UserModel(user) : null;
};
export class UserRepository {
  constructor(
    private db: DODexie,
    private fetch: FetchWrapper,
    private secureKVStore: SecureKeyValueStore,
    private kv: KeyValueStore,
    private journalParticipantRepository: JournalParticipantRepository,
  ) {}
  async getActiveUser() {
    // Note we only store one user at the moment.
    return maybeMakeModel(await this.db.users.get("me"));
  }

  subscribeToUser(callback: (user: UserModel | null) => void) {
    const sub = liveQuery(() => this.db.users.get("me")).subscribe(
      (u) => {
        callback(maybeMakeModel(u));
      },
      (err) => Sentry.captureException(err),
    );
    return () => {
      sub.unsubscribe();
    };
  }

  subscribeToAuthToken(callback: (token: AuthToken | undefined) => void) {
    const sub = liveQuery(() =>
      this.secureKVStore.get<AuthToken>(
        "dayone-auth-token",
        deserializeAuthToken,
      ),
    ).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }

  async saveActiveUserFromAPI(user: User) {
    const newValues = await this.getNewDbUserIfChanged(user);
    newValues && this.db.users.put(newValues);
  }

  async getNewDbUserIfChanged(user: User) {
    const existingUserFromDb = await this.db.users.get("me");

    const newValues: UserDBRow = {
      local_id: "me",
      id: user.id,
      avatar: user.avatar || "",
      bio: user.bio || "",
      display_name: user.displayName || "",
      email: user.email || undefined,
      sync_upload_base_url: user.syncUploadBaseUrl,
      journal_order: user.journalOrder.map(String) || [],
      shared_journal_order: user.sharedJournalOrder || [],
      unified_journal_order: user.unifiedJournalOrder || [],
      subscription_status: user.featureBundle.bundleName,
      features: [
        ...user.featureBundle.features,
        ...user.featureBundle.featuresFull,
      ],
      credentials: JSON.stringify(user.credentials.map(String)) || "",
      profile_color: user.profileColor,
      website: user.website || "",
      shared_profile: user.sharedProfile || "",
      initials: user.initials || "",
      master_key_storage: user.master_key_storage || [],
    };

    return !isDeepEqual(existingUserFromDb, newValues) ? newValues : null;
  }

  async update(props: Partial<UserDBRow>): Promise<UserModel | null> {
    const current = await this.getActiveUser();
    if (!current) return null;
    const newValues: UserDBRow = { ...current.data, ...props, local_id: "me" };
    this.db.users.put(newValues);
    return maybeMakeModel(newValues);
  }

  async setAvatar(avatar: string) {
    return this.update({ avatar });
  }

  async refetchUserFromServerIfStored(): Promise<User | null> {
    const user = await this.getActiveUser();
    if (user) {
      const res = await this.fetch.fetchAPI("/users/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        const json = await res.json();
        return json;
      } else {
        Sentry.captureException(
          new Error(
            `Error synchronizing user: ${res.status}. Res: ${JSON.stringify(
              res,
            )}`,
          ),
        );
      }
    }
    return null;
  }

  async updateUserEmail(userId: string, email: string) {
    await this.fetch.fetchAPI("/v3/users/change-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newEmail: email }),
    });

    return null;
  }

  async updateUserOnServer(partialUser: Partial<UpdateServerUser>) {
    const user = await this.getActiveUser();
    if (!user) return null;
    const realName = partialUser.display_name || user.display_name;
    const avatar = "avatar" in partialUser ? partialUser.avatar : user.avatar;
    const bio = partialUser.bio || user.bio;
    const website = partialUser.website || user.website;
    const sharedProfile =
      partialUser.shared_profile || user.shared_profile || null;
    const profileColor = partialUser.profile_color || user.profile_color;
    const initials = partialUser.initials || user.initials;

    const res = await this.fetch.fetchAPI("/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        realName,
        avatar,
        bio,
        website,
        sharedProfile,
        profileColor,
        initials,
      }),
    });

    if (res.status === 200) {
      // Only write the update if the request succeeded
      const result = await this.update(partialUser);

      if (result) {
        // we don't need to show an error if this fails, it will get updated on the next journal sync
        await this.journalParticipantRepository.updateParticipant(result);
      }
      return result;
    }
  }

  async uploadAvatar(avatar: File) {
    const mediaRes = await this.fetch.fetchAPI("/media", {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: avatar,
    });

    if (mediaRes.status === 200) {
      const { id } = await mediaRes.json();
      return id;
    }

    return null;
  }

  async addUserPassword(newPassword: string) {
    const res = await this.fetch.postJson("/v2/users/add-password", {
      new: newPassword,
    });

    if (res.status === 200) {
      return { success: true };
    } else {
      const body = await res.text();
      return { success: false, error: body };
    }
  }

  async changeUserPassword(currentPassword: string, newPassword: string) {
    const res = await this.fetch.postJson("/users/password", {
      current: currentPassword,
      new: newPassword,
    });

    if (res.status === 200) {
      return { success: true };
    } else {
      const body = await res.text();
      return { success: false, error: body };
    }
  }

  async getSubscriptionForActiveUser() {
    const res = await this.fetch.fetchAPI("/v2/users/account-status", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      return res.json() as Promise<SubscriptionInfo>;
    } else {
      const isLoggingOut = await this.kv.get("is-logging-out");
      if (!(isLoggingOut && res.status === 403)) {
        Sentry.captureException(new Error("Unable to load subscription info."));
      }
    }
  }

  async updateJournalOrder(personalOrder: string[], sharedOrder: string[]) {
    const user = await this.getActiveUser();
    if (user?.journal_order) {
      const res = await this.fetch.fetchAPI("/v2/users/journal-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          private_journal_order: personalOrder,
          shared_journal_order: sharedOrder,
        }),
      });
      if (res.status === 200) {
        return this.saveActiveUserFromAPI(await res.json());
      } else {
        Sentry.captureException(
          new Error(
            `Error saving journal order - Status: ${
              res.status
            } - Res: ${JSON.stringify(res)}`,
          ),
        );
      }
    }
    return null;
  }

  async updateUnifiedJournalOrder(order: string[]) {
    const user = await this.getActiveUser();
    if (user?.journal_order) {
      const res = await this.fetch.fetchAPI("/v3/users/journal-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          unified_journal_order: order,
        }),
      });
      if (res.status === 200) {
        return this.saveActiveUserFromAPI(await res.json());
      } else {
        Sentry.captureException(
          new Error(
            `Error saving journal order - Status: ${
              res.status
            } - Res: ${JSON.stringify(res)}`,
          ),
        );
      }
    }
    return null;
  }

  async test_clearActiveUser() {
    await this.db.users.clear();
  }

  async getAuthToken() {
    return this.secureKVStore.get<AuthToken>(
      "dayone-auth-token",
      deserializeAuthToken,
    );
  }

  async authTokenNeedsRefresh() {
    const authToken = await this.getAuthToken();
    if (!authToken) return true;
    return authToken.timestamp < Date.now() - AUTH_TOKEN_EXPIRY_TIME;
  }

  async getQRLoginSecret(nonce: string) {
    const res = await this.fetch.fetchAPI("/v2/users/login/qr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ nonce }),
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      Sentry.captureException(
        new Error(
          `Error getting secret for  login - Status: ${
            res.status
          } - Res: ${JSON.stringify(res)}`,
        ),
      );
    }
  }

  async updateMasterKeyStorageLocation(locations: string[]) {
    this.db.users.update("me", { master_key_storage: locations });
  }

  async fetchFeatureFlags() {
    const res = await this.fetch.fetchAPI("/feature-flags", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      const flags = await res.json();
      // Save each flag to the database
      // TODO remove flags removed from the server
      await this.db.server_flags.bulkPut(flags);

      return flags;
    } else {
      Sentry.captureException(
        new Error(
          `Error fetching feature flags: ${res.status}. Res: ${JSON.stringify(
            res,
          )}`,
        ),
      );
      return null;
    }
  }

  subscribeToLabFeatures(callback: (flags: ServerFlagDBRow[]) => void) {
    const sub = liveQuery(() => {
      return this.db.server_flags
        .filter((flag) => flag.is_labs_feature === true)
        .toArray();
    }).subscribe(callback, (err) => {
      console.error("Error fetching lab features:", err);
      Sentry.captureException(err);
    });

    return () => {
      sub.unsubscribe();
    };
  }
}
