import { Button } from "@wordpress/components";

import { Icon } from "@/components/Icon";

export const BackupButton = ({
  icon,
  children,
  onClick,
  backedUp = false,
  position,
  label,
  disabled,
}: {
  icon: any;
  children: any;
  onClick: () => void;
  backedUp?: boolean;
  position?: "top" | "bottom";
  label?: string;
  disabled?: boolean;
}) => (
  <Button
    variant="secondary"
    disabled={disabled}
    sx={{
      "&&&": {
        py: 4,
        px: 2,
        fontSize: 0,
        maxWidth: "100%",
        whiteSpace: "normal",
        border: "1px solid",
        color: "textPrimary",
        transition: "all 0.05s ease-in-out",
        borderColor: "borderPrimaryOpaque",

        "&:hover": {
          color: "textPrimary",
          borderColor: "borderPrimaryOpaque",
          bg: "surfaceHover",
          zIndex: 1,
        },
        ...(position === "top"
          ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              // I know this seems hacky but
              // it allows for the hover effect to
              // work on the bottom border
              mb: "-1px",
            }
          : position === "bottom"
            ? {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }
            : position === "center"
              ? {
                  borderRadius: 0,
                }
              : {}),
      },
      width: "100%",
    }}
    onClick={onClick}
    label={label}
    tooltipPosition={"bottom center"}
    showTooltip
    icon={
      <div
        sx={{
          width: 6,
          height: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          mx: 2,
          "&&& > svg": {
            mr: 0,
          },
        }}
      >
        {icon}
      </div>
    }
  >
    <span
      sx={{
        flexGrow: 1,
        textAlign: "left",
        display: "block",
        webkitLineClamp: 2,
        webkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexShrink: 1,
      }}
    >
      {children}
    </span>
    {backedUp ? (
      <div sx={{ ml: "auto" }}>
        <Icon icon="checkmark" size={3} sx={{ color: "check" }} />
      </div>
    ) : null}
  </Button>
);
