import { Button } from "@wordpress/components";
import { dateI18n } from "@wordpress/date";
import { useI18n } from "@wordpress/react-i18n";
import { format, isSameDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useLilius } from "use-lilius";
import { getWeekStartByLocale } from "weekstart";

import { Icon } from "@/components/Icon";
import { getDateFromInput } from "@/components/Search/DateFilter";
import { dayOneBlue } from "@/styles/theme";
import { getMonthAsString } from "@/utils/date-helper";

type Props = {
  startDate?: string; // YYYY-MM-DD
  endDate?: string; // YYYY-MM-DD
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
};

type CalendarDay = {
  date: Date;
  isCurrentMonth: boolean;
  isSelected: boolean;
  isStart: boolean;
  isEnd: boolean;
  label: string;
};

const startOfWeek = getWeekStartByLocale(navigator.language);

export const DateRangeSelector: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { __ } = useI18n();
  const currentDate = startDate ? getDateFromInput(startDate) : new Date();
  const [currentMonthYear, setCurrentMonthYear] = useState<{
    year: number;
    month: number;
    firstOfMonth: Date;
  }>({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    firstOfMonth: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    ),
  });
  const [hoverDate, setHoverDate] = useState<Date | null>(null);
  const monthTitle = getMonthAsString(
    currentMonthYear?.firstOfMonth || new Date(),
  );

  const {
    calendar,
    setViewing,
    viewPreviousMonth,
    viewNextMonth,
    selectRange,
    isSelected,
    deselectRange,
  } = useLilius({
    weekStartsOn: startOfWeek,
    viewing: currentMonthYear.firstOfMonth,
  });
  const totalDays: CalendarDay[] = (calendar.flat(Infinity) as Date[]).map(
    (day) => {
      const label = getMonthAsString(day);
      return {
        date: day,
        isCurrentMonth: label === monthTitle,
        isSelected: isSelected(day),
        isStart: startDate
          ? isSameDay(day, getDateFromInput(startDate))
          : false,
        isEnd: endDate ? isSameDay(day, getDateFromInput(endDate)) : false,
        label: format(day, "dd, MMMM yyyy,"),
      };
    },
  );

  const clearSelction = () => {
    deselectRange(totalDays[0].date, totalDays[totalDays.length - 1].date);
  };

  const dayButtonClick = (day: Date) => {
    const dayString = format(day, "yyyy-MM-dd");
    if (!startDate) {
      setStartDate(format(day, "yyyy-MM-dd"));
      return;
    }
    if (!endDate) {
      const start = getDateFromInput(startDate);
      if (day < start) {
        setStartDate(dayString);
        setEndDate(format(start, "yyyy-MM-dd"));
        return;
      }
      setEndDate(dayString);
      return;
    }
    if (startDate && endDate) {
      const start = getDateFromInput(startDate);
      const end = getDateFromInput(endDate, true);
      if (isSameDay(day, start) || isSameDay(day, end)) {
        setStartDate(dayString);
        setEndDate(dayString);
        selectRange(day, day, true);
        return;
      }
      if (day < start) {
        setStartDate(dayString);
        selectRange(day, end, true);
        return;
      }
      if (day > end) {
        setEndDate(dayString);
        selectRange(start, day, true);
        return;
      }
    }
    setStartDate(dayString);
    setEndDate("");
    selectRange(day, day, true);
  };

  const daysOfWeek = useMemo(() => {
    return calendar[0][0].map((day) => (
      <div
        key={day.toString()}
        sx={{ fontSize: 1, color: "textTertiary", textAlign: "center", my: 2 }}
      >
        {dateI18n("D", day, -day.getTimezoneOffset())}
      </div>
    ));
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const [startYear, startMonth] = startDate.split("-");
      const start = getDateFromInput(startDate);
      const end = getDateFromInput(endDate, true);
      const firstOfMonth = new Date(
        parseInt(startYear),
        parseInt(startMonth) - 1,
        1,
      );
      setCurrentMonthYear({
        year: firstOfMonth.getFullYear(),
        month: firstOfMonth.getMonth(),
        firstOfMonth,
      });
      setViewing(firstOfMonth);
      selectRange(start, end, true);
    }
    if (!startDate && !endDate) {
      clearSelction();
    }
  }, [startDate, endDate]);
  return (
    <>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          label={__("Previous month")}
          icon={<Icon icon="chevron-left-small" />}
          onClick={() => {
            const year =
              currentMonthYear.month === 0
                ? currentMonthYear.year - 1
                : currentMonthYear.year;
            const month =
              currentMonthYear.month === 0 ? 11 : currentMonthYear.month - 1;
            const firstOfMonth = new Date(year, month, 1);
            setCurrentMonthYear({
              year,
              month,
              firstOfMonth,
            });
            viewPreviousMonth();
          }}
        />
        <span>{monthTitle}</span>
        <Button
          label={__("Next month")}
          icon={<Icon icon="chevron-right-small" />}
          onClick={() => {
            const year =
              currentMonthYear.month === 11
                ? currentMonthYear.year + 1
                : currentMonthYear.year;
            const month =
              currentMonthYear.month === 11 ? 0 : currentMonthYear.month + 1;
            const firstOfMonth = new Date(year, month, 1);
            setCurrentMonthYear({
              year,
              month,
              firstOfMonth,
            });
            viewNextMonth();
          }}
        />
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
        }}
      >
        {daysOfWeek}
        {totalDays.map((day, index) => (
          <button
            sx={{
              color: getDayColor(day),
              textAlign: "center",
              fontSize: 1,
              aspectRatio: "1/1",
              backgroundColor: getDayBackground(
                day,
                hoverDate,
                startDate ? getDateFromInput(startDate) : undefined,
              ),
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: day.isStart ? "circle" : "none",
              borderBottomLeftRadius: day.isStart ? "circle" : "none",
              borderTopRightRadius: day.isEnd ? "circle" : "none",
              borderBottomRightRadius: day.isEnd ? "circle" : "none",
              m: 0,
              p: 0,
              border: 0,
              "&:hover": {
                backgroundColor: "surfaceHover",
                color: "textPrimary",
              },
            }}
            key={index}
            aria-label={day.label}
            onClick={() => dayButtonClick(day.date)}
            onMouseEnter={() => {
              setHoverDate(day.date);
            }}
            onMouseLeave={() => {
              setHoverDate(null);
            }}
          >
            {day.date.getDate()}
          </button>
        ))}
      </div>
    </>
  );
};

const getDayColor = (day: CalendarDay) => {
  if (day.isStart || day.isEnd) {
    return "white";
  }
  return day.isCurrentMonth ? "textPrimary" : "textTertiary";
};

const getDayBackground = (
  day: CalendarDay,
  hoverDate: Date | null,
  startDate?: Date,
) => {
  if (hoverDate && startDate && !day.isStart && !day.isEnd) {
    if (day.date > startDate) {
      if (day.date < hoverDate) {
        return "surfaceHover";
      }
    }
    if (day.date < startDate) {
      if (day.date > hoverDate) {
        return "surfaceHover";
      }
    }
  }
  if (day.isStart || day.isEnd) {
    return dayOneBlue;
  }
  return day.isSelected ? "surfaceActive" : "transparent";
};
