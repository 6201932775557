import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Icon } from "@/components/Icon";
import { ItemButton, ItemGroup } from "@/components/ItemGroup";
import { ExportButton } from "@/components/JournalSettings/ExportButton";
import { JournalSettingsAdvancedView } from "@/components/JournalSettings/JournalSettingsAdvancedView";
import { JournalSettingsModalHeader } from "@/components/JournalSettings/JournalSettingsModalHeader";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { JournalSettingsStartView } from "@/components/JournalSettings/JournalSettingsStartView";
import { ViewName } from "@/components/JournalSettings/JournalSettingsTypes";
import { JournalSharingInfoButton } from "@/components/JournalSettings/JournalSharingInfoButton";
import { SharedJournalSettings } from "@/components/JournalSettings/SharedJournalSettings";
import { SimplifiedJournalForm } from "@/components/JournalSettings/SimplifiedJournalForm";
import { getAllEntriesURL } from "@/data/URLFunctions";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { JournalStore } from "@/data/stores/JournalStore";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";

type Props = {
  handleClose: () => void;
  journalID: string;
  journalStore: JournalStore;
  primaryViewState: PrimaryViewState;
  snackbar: SnackbarViewState;
  inModal?: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
  setParentView?: (view: ViewName) => void;
  parentView?: ViewName | null;
};

export const EditJournalMain: React.FC<Props> = observer(
  ({
    handleClose,
    journalID,
    journalStore,
    primaryViewState,
    snackbar,
    inModal = true,
    modalRef,
    setParentView,
    parentView,
  }) => {
    const { __, _x } = useI18n();
    const currentJournal = primaryViewState.getJournalById(journalID);
    const canEncrypt = primaryViewState.isEncryptionReady;
    const templates = primaryViewState.templates;
    const [location, setLocation] = useLocation();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [direction, setDirection] = useState<"left" | "right">("right");

    const [view, setView] = useState<ViewName>(parentView ?? "start");

    useEffect(() => {
      setView(parentView ?? "start");
    }, [parentView]);

    const unsyncable = currentJournal
      ? primaryViewState.isJournalUnsyncable(currentJournal.id)
      : false;

    const handleSyncValue = async (value: boolean) => {
      if (value !== unsyncable) {
        if (value == true) {
          await journalStore.makeUnsyncable(journal.id);
          analytics.tracks.recordEvent(EVENT.journalSyncOff, {});
          setLocation("/");
        } else {
          await journalStore.makeSyncable(journal.id);
          analytics.tracks.recordEvent(EVENT.journalSyncOff, {});
        }
      }
    };

    const [error, setError] = useState<string | null>(null);
    if (!currentJournal) {
      setError(__("Journal not found"));
      return null;
    }
    const [journal, setJournal] = useState(currentJournal);
    const isEditRestricted =
      journal.is_shared && primaryViewState.user?.id !== journal.owner_id;

    const changeView = (newView: ViewName) => {
      setDirection(view === "start" ? "right" : "left");
      setView(newView);
      if (setParentView) {
        setParentView(newView);
      }
      let selector = ".components-modal__content";
      if (!inModal) {
        selector = ".components-modal__content main";
      }
      modalRef.current?.querySelector(selector)?.scrollTo({ top: 0 });
    };

    const handleDeleteJournal = async () => {
      handleClose();
      const result = await journalStore.deleteJournal(journal);
      if (result) {
        analytics.tracks.recordEvent(EVENT.journalDelete, {});
        if (journal.id && location.includes(journal.id)) {
          setLocation(getAllEntriesURL(primaryViewState.selectedEntryView));
        }
      } else {
        setError(__("Could not delete journal. Please try again"));
      }
    };

    const participantCount = journal.participants.filter(
      (p) => p.role !== "previous",
    ).length;

    const toggleCommentsDisabled = async (value: number) => {
      const result = await journalStore.updateCommentsDisabled(
        journal.id,
        value,
      );

      if (result) {
        setJournal({ ...journal, comments_disabled: value });
        snackbar.newMessage(__("Journal updated"));
      }
    };

    const saveJournal = async (journal: JournalDBRow) => {
      if (!journal) {
        setError(__("Journal not found"));
        return;
      }
      if (journal.name === "") {
        setError(__("Journal name cannot be blank"));
        return;
      }
      if (!error) {
        try {
          const journalAfterSave = await journalStore.saveJournal(journal);
          if (journalAfterSave) {
            snackbar.newMessage(__("Journal updated"));
            analytics.tracks.recordEvent(EVENT.journalUpdate, {
              shared_journal: journalAfterSave.is_shared,
              conceal: !!journalAfterSave.conceal,
              hide_on_this_day: !!journalAfterSave.hide_on_this_day,
              hide_today_view: !!journalAfterSave.hide_today_view,
              hide_streaks: !!journalAfterSave.hide_streaks,
              add_location_to_entries:
                journalAfterSave.add_location_to_new_entries,
            });
            setJournal(journalAfterSave);
          }
        } catch (e) {
          Sentry.captureException(e);
          setError(__("Could not update journal. Please try again"));
        }
      }
    };

    return (
      <>
        {error && <ErrorMessage message={error} />}
        <AnimatePresence initial={false}>
          {inModal && view === "start" && (
            <JournalSettingsModalHeader key="header">
              <span>
                {journal.is_shared
                  ? __("Edit Shared Journal")
                  : __("Edit Journal")}
              </span>
            </JournalSettingsModalHeader>
          )}
          {view === "start" && !isEditRestricted && (
            <>
              <JournalSettingsStartView
                key="settings-start"
                journal={journal}
                setView={changeView}
                setJournal={saveJournal}
                primaryViewState={primaryViewState}
                isEditing={true}
                inModal={inModal}
                direction={direction}
              />
              {journal.is_shared && (
                <JournalSettingsSlideContent
                  key="settings-slide"
                  name="sharing"
                  direction={direction}
                >
                  <JournalSharingInfoButton
                    key="sharing-info"
                    participantCount={participantCount}
                    setView={changeView}
                  />
                </JournalSettingsSlideContent>
              )}
              <ExportButton journalId={journal.id} />
              <JournalSettingsAdvancedView
                key="settings-advanced"
                journal={journal}
                setView={changeView}
                setJournal={saveJournal}
                toggleCommentsDisabled={toggleCommentsDisabled}
                canEncrypt={canEncrypt}
                templates={templates}
                isEditing={true}
                onJournalSyncToggle={handleSyncValue}
                newUnsyncableValue={unsyncable}
                inModal={inModal}
                direction={direction}
                showHeading={true}
              />
              <ItemGroup addMargin={true} key="settings-delete">
                <ItemButton
                  isDestructive={true}
                  sx={{
                    minHeight: "48px",
                    "&&": { color: "journalRed" },
                  }}
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  {__("Delete Journal")}
                </ItemButton>
              </ItemGroup>
            </>
          )}
          {view === "start" && isEditRestricted && (
            <JournalSettingsSlideContent
              name="simplified"
              direction="right"
              key="settings-simplified"
            >
              <SimplifiedJournalForm
                journal={journal}
                setJournal={saveJournal}
                setView={changeView}
                participantCount={participantCount}
                primaryViewState={primaryViewState}
                journalStore={journalStore}
                handleClose={handleClose}
                setError={setError}
                onJournalSyncToggle={handleSyncValue}
                newUnsyncableValue={unsyncable}
              />
            </JournalSettingsSlideContent>
          )}
          {view === "sharing" && (
            <>
              <JournalSettingsSlideContent
                name="sharing"
                direction="left"
                key="slide"
              >
                <SharedJournalSettings journalId={journal.id} />
              </JournalSettingsSlideContent>
              {inModal && (
                <JournalSettingsModalHeader key="header">
                  <ItemButton
                    onClick={() => {
                      setView("start");
                    }}
                    label={__("Back to journal settings")}
                  >
                    <Icon icon="chevron-left-small" />
                    {__("Journal Sharing")}
                  </ItemButton>
                </JournalSettingsModalHeader>
              )}
            </>
          )}
        </AnimatePresence>
        {confirmDelete && (
          <ConfirmDialog
            handleClose={() => setConfirmDelete(false)}
            title={sprintf(
              _x(
                "Delete %s",
                "Title to confirm journal deletion including journal name",
              ),
              journal.name,
            )}
            message={__(
              "By deleting this journal, all associated entries will be permanently lost. Are you sure you want to continue?",
            )}
            actionLabel={__("Delete Journal")}
            handleAction={handleDeleteJournal}
            isDestructive={true}
          />
        )}
      </>
    );
  },
);
