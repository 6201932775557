import { startAuthentication } from "@simplewebauthn/browser";

import { FetchWrapper } from "@/api/FetchWrapper";

export class LoginAPICaller {
  constructor(private fetchWrapper: FetchWrapper) {}

  async loginWithPasssword(email: string, password: string) {
    const res = await this.fetchWrapper.postJson(
      "/users/login",
      {
        email,
        password,
      },
      { expectedStatusCodes: [404] },
    );
    return res;
  }

  async loginWithQR(nonce: string, secret: string) {
    const res = await this.fetchWrapper.postJson(
      "/v2/users/login/qr/redeem",
      {
        nonce,
        secret,
      },
      { expectedStatusCodes: [400, 404, 408] },
    );
    return res;
  }

  async loginWithApple(token: string) {
    const res = await this.fetchWrapper.fetchAPI("/users/sign-in-with-apple", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: token,
    });
    return res;
  }

  async loginWithExistingCloudkitUser(token: string) {
    const body = {
      token,
      environment: "production",
      tokenName: "web",
    };
    const res = await this.fetchWrapper.postJson(
      "/users/cloudkit/existing-user",
      body,
    );
    return res;
  }

  async loginWithGoogle(token: string) {
    const res = await this.fetchWrapper.fetchAPI("/users/google-auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: token,
    });
    return res;
  }

  async loginWithPasskey(
    id: string,
    cred: Awaited<ReturnType<typeof startAuthentication>>,
  ) {
    const resp = await this.fetchWrapper.fetchAPI(
      "/v2/users/login/webauthn",
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
          response: cred,
        }),
      },
      {
        referrer: true,
      },
    );
    return resp;
  }

  async logout() {
    return this.fetchWrapper.fetchAPI("/users/logout");
  }
}
