import { TextControl } from "@wordpress/components";
import { FormEvent, useRef } from "react";

import { Icon } from "@/components/Icon";
import {
  ClearSearchOptionsType,
  SearchBoxClear,
} from "@/components/SearchBoxCancel";

type Props = {
  value: string | null;
  setInputValue: (value: string | null) => void;
  onSubmit?: (value: string) => void;
  placeholder: string;
  className?: string;
  onSubmitNullifyInput?: boolean;
  disabled?: boolean;
  showClear?: ClearSearchOptionsType;
  shouldFocus?: boolean;
  showSearchIcon?: boolean;
};

export const SearchBox: React.FC<Props> = ({
  value,
  setInputValue,
  onSubmit = () => {},
  placeholder,
  className,
  onSubmitNullifyInput = false,
  disabled = false,
  showClear = "button",
  shouldFocus = false,
  showSearchIcon = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const input = value?.trim();
    if (!input) {
      return;
    }
    onSubmit(input);
    onSubmitNullifyInput && setInputValue(null);
  };

  return (
    <div
      sx={{
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "borderPrimary",
        position: "relative",
        height: "48px",
      }}
      className={className}
    >
      <form
        sx={{
          width: "100%",
          height: "100%",
          "& .components-base-control__field": {
            m: 0,
          },
          "& > div, & > div > div": {
            height: "100%",
          },
          "& .components-text-control__input": {
            borderRadius: "0 !important",
            width: "100%",
            fontSize: 1,
            border: "none",
            bg: "transparent",
            color: "textPrimary",
            pl: 7,
            pr: 5,
            height: "100%",
          },
          "& .components-text-control__input::placeholder": {
            color: "textSecondary",
          },
        }}
        onSubmit={handleSubmit}
      >
        {showSearchIcon && (
          <span
            sx={{
              position: "absolute",
              left: "20px",
              top: "13px",
              color: "textSecondary",
              fill: "currentcolor",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Icon
              icon="search"
              sx={{ mr: 2, transform: "rotate(90deg)" }}
              size={4}
              iconStyles={{ fontWeight: "heading" }}
            />
          </span>
        )}
        <TextControl
          __nextHasNoMarginBottom
          ref={inputRef}
          sx={{ margin: 0, borderRadius: 0 }}
          value={value || ""}
          onChange={setInputValue}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={shouldFocus}
        />
        {value && (
          <SearchBoxClear
            showClear={showClear}
            handleClear={() => {
              setInputValue(null);
              inputRef.current?.focus();
            }}
          />
        )}
      </form>
    </div>
  );
};
