import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance, cloneBlock, createBlock } from "@wordpress/blocks";
import { useDispatch, useRegistry, useSelect } from "@wordpress/data";

import {
  GALLERY_BLOCK_ID,
  IMAGE_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import {
  getGalleryMoments,
  MomentIdsWithGalleryData,
  MomentIdsWithModel,
} from "@/utils/gallery";

/**
 * Moves an item from one index to another in an array.
 *
 * @param array - The array to move the item in.
 * @param fromIndex - The index to move the item from.
 * @param toIndex - The index to move the item to.
 * @returns A new array with the item moved to the new position.
 */
const moveItem = (array: any[], fromIndex: number, toIndex: number) => {
  const newArray = [...array];
  // Ensure the indices are within bounds
  if (
    fromIndex >= 0 &&
    fromIndex < array.length &&
    toIndex >= 0 &&
    toIndex < array.length
  ) {
    const [item] = newArray.splice(fromIndex, 1); // Remove the item from the original position
    newArray.splice(toIndex, 0, item); // Insert it at the new position
  }
  return newArray;
};

export const useGalleryMedia = () => {
  const {
    insertBlocks,
    moveBlocksToPosition,
    replaceBlocks,
    replaceBlock,
    replaceInnerBlocks,
    selectionChange,
    updateBlockAttributes,
  } = useDispatch(blockEditorStore);

  const {
    getBlocks,
    getBlock,
    getBlockIndex,
    getNextBlockClientId,
    getBlockRootClientId,
  } = useSelect(blockEditorStore, []);
  const registry = useRegistry();

  /**
   * Calculates the aspect ratio and gallery height of the media blocks in a gallery.
   *
   * @param blocks - The gallery moments matching the gallery blocks.
   */
  const calculateMediaSizes = (galleryMoments: MomentIdsWithGalleryData[]) => {
    const { blockIDs, attrs } = galleryMoments.reduce<{
      blockIDs: string[];
      attrs: Record<string, { aspectRatio: number; galleryHeight: number }>;
    }>(
      (acc, curr) => {
        if (!curr.blockClientId) {
          return acc;
        }
        acc.blockIDs.push(curr.blockClientId);
        acc.attrs[curr.blockClientId] = {
          aspectRatio: curr.aspectRatio,
          galleryHeight: curr.height,
        };
        return acc;
      },
      { blockIDs: [], attrs: {} },
    );
    updateBlockAttributes(blockIDs, attrs, true);
  };

  /**
   * Moves a media block to a new position in a gallery.
   *
   * @param clientId - The client ID of the media block to move.
   * @param fromBlockClientId - The client ID of the block the media is being moved from.
   * @param toBlockClientId - The client ID of the block the media is being moved to.
   * @param index - The index to move the media to.
   */
  const moveMediaToPosition = async (
    clientId: string,
    fromBlockClientId: string,
    toBlockClientId: string,
    index: number,
  ) => {
    let toBlocks: BlockInstance[] = [...getBlocks(toBlockClientId)];

    // Moving the block within the same block.
    // We'll change the blocks array to reflect the new order.
    // This needs to be done before we get the gallery moments
    // so that we can use the correct blocks array to calculate the image sizes.
    if (fromBlockClientId === toBlockClientId) {
      const indexOfMovedBlock = toBlocks.findIndex(
        (block) => block.clientId === clientId,
      );
      toBlocks = moveItem(toBlocks, indexOfMovedBlock, index);
    }

    const galleryMoments: MomentIdsWithGalleryData[] =
      await getGalleryMoments(toBlocks);

    let sourceBlockMoments: MomentIdsWithGalleryData[] = [];
    // If the block is being moved from one gallery to another,
    // we need to get the moments of the source gallery to update
    // the aspect ratios as well.
    if (fromBlockClientId && fromBlockClientId !== toBlockClientId) {
      const sourceBlocks = [...getBlocks(fromBlockClientId)];
      sourceBlocks.filter((block) => block.clientId !== clientId);
      sourceBlockMoments = await getGalleryMoments(sourceBlocks);
    }

    // @ts-ignore Types are not correct, but the code is.
    // This is a single action; the batching prevents creating multiple history records.
    registry.batch(() => {
      // This is a single action; the batching prevents creating multiple history records.
      // @ts-ignore Types are not correct
      moveBlocksToPosition(
        [clientId],
        fromBlockClientId,
        toBlockClientId,
        index,
      );

      calculateMediaSizes(galleryMoments);

      // If the block is being moved from one gallery to another,
      // we need to update the aspect ratios of the source gallery as well.
      if (sourceBlockMoments.length > 0) {
        calculateMediaSizes(sourceBlockMoments);
      }
    });
  };

  /**
   * Merges two image Blocks into a gallery.
   *
   * @param fromBlockClientId - The client ID of the block the media is being moved from.
   * @param toBlockClientId - The client ID of the block the media is being moved to.
   * @param journalId - The journal ID of the gallery.
   * @param entryId - The entry ID of the gallery.
   * @param blockIndex - The index to move the media to.
   */
  const createGalleryFromBlocks = async (
    fromBlockClientId: string,
    toBlockClientId: string,
    journalId: string,
    entryId: string,
    blockIndex: number,
  ) => {
    const fromBlock = getBlock(fromBlockClientId);
    const toBlock = getBlock(toBlockClientId);

    const innerBlocks = [cloneBlock(toBlock)];
    const newBlock = cloneBlock(fromBlock);
    innerBlocks.splice(blockIndex, 0, newBlock);

    const galleryMoments = await getGalleryMoments(innerBlocks);

    // @ts-ignore Types are not correct, but the code is.
    // This is a single action; the batching prevents creating multiple history records.
    registry.batch(() => {
      // This is a single action; the batching prevents creating multiple history records.
      const galleryBlock = createBlock(
        GALLERY_BLOCK_ID,
        {
          journalId,
          entryId,
        },
        innerBlocks,
      );
      // This means we're adding images to an existing image or gallery
      replaceBlocks([toBlockClientId, fromBlockClientId], galleryBlock);
      calculateMediaSizes(galleryMoments);
    });
  };

  /**
   * Creates a new gallery block and moves moments into it.
   *
   * @param moments - The moments to add to the gallery.
   * @param journalId - The journal ID of the gallery.
   * @param entryId - The entry ID of the gallery.
   * @param blockIndex - The index to move the moments to.
   * @param cursorPosition - The position to insert the gallery at.
   * @param existingBlockClientId - The client ID of the block to add the moments to.
   */
  const createGalleryFromMoments = async (
    moments: MomentIdsWithModel[],
    journalId: string,
    entryId: string,
    blockIndex: number,
    cursorPosition: number,
    existingBlockClientId?: string,
  ) => {
    const existingBlock = existingBlockClientId
      ? getBlock(existingBlockClientId)
      : undefined;

    // Create the new inner blocks from the new moments
    const newInnerBlocks = moments.map(
      ({ clientId, entryId, journalId, type }) => {
        return createBlock(type === "photo" ? IMAGE_BLOCK_ID : VIDEO_BLOCK_ID, {
          clientId,
          entryId,
          journalId,
        });
      },
    );

    if (existingBlock) {
      if (existingBlock.name === GALLERY_BLOCK_ID) {
        const existingBlocks = [...getBlocks(existingBlock.clientId)];
        existingBlocks.splice(blockIndex, 0, ...newInnerBlocks);
        const galleryMoments = await getGalleryMoments(existingBlocks);

        // @ts-ignore Types are not correct, but the code is.
        // This is a single action; the batching prevents creating multiple history records.
        registry.batch(() => {
          insertBlocks(newInnerBlocks, blockIndex, existingBlock.clientId);
          calculateMediaSizes(galleryMoments);
        });
        return;
      } else {
        // Creating a new gallery from a solo media block and the new moment
        const innerBlocks = [cloneBlock(existingBlock)];
        innerBlocks.splice(blockIndex, 0, ...newInnerBlocks);

        const galleryMoments = await getGalleryMoments(innerBlocks);

        // @ts-ignore Types are not correct, but the code is.
        // This is a single action; the batching prevents creating multiple history records.
        registry.batch(() => {
          const galleryBlock = createBlock(
            GALLERY_BLOCK_ID,
            {
              journalId,
              entryId,
            },
            innerBlocks,
          );

          // Replace the existing image block with a new gallery block
          replaceBlock(existingBlock.clientId, galleryBlock);
          calculateMediaSizes(galleryMoments);
        });
        return;
      }
    } else {
      if (moments.length === 1) {
        // Insert a new image or video block
        insertBlocks(
          createBlock(
            moments[0].type === "photo" ? IMAGE_BLOCK_ID : VIDEO_BLOCK_ID,
            {
              clientId: moments[0].clientId,
              entryId,
              journalId,
            },
          ),
          cursorPosition,
        );
      } else {
        const galleryMoments = await getGalleryMoments(newInnerBlocks);

        // @ts-ignore Types are not correct, but the code is.
        // This is a single action; the batching prevents creating multiple history records.
        registry.batch(() => {
          // If there is no existing block, we create a new gallery
          const galleryBlock = createBlock(
            GALLERY_BLOCK_ID,
            {
              journalId,
              entryId,
            },
            newInnerBlocks,
          );
          insertBlocks(galleryBlock, cursorPosition);
          calculateMediaSizes(galleryMoments);
        });
      }
    }
  };

  /**
   * Split a gallery block into two gallery block
   *
   * @param clientId - The client ID of the block the media is being split from
   * @param journalId - The journal ID of the gallery.
   * @param entryId - The entry ID of the gallery.
   * @param blockIndex - The index to move the media to.
   */
  const splitGalleryBlock = (
    clientId: string,
    journalId: string,
    entryId: string,
  ) => {
    const rootClientId = getBlockRootClientId(clientId);
    const rootBlockIndex = getBlockIndex(rootClientId);
    const nextBlock = getBlock(getNextBlockClientId(rootClientId));
    const currentGalleryBlock = getBlock(rootClientId);
    const blockIndex = getBlockIndex(clientId);
    const isLastGalleryItem =
      blockIndex === currentGalleryBlock.innerBlocks.length - 1;

    // @ts-ignore Types are not correct, but the code is.
    // This is a single action; the batching prevents creating multiple history records.
    registry.batch(() => {
      replaceInnerBlocks(
        rootClientId,
        currentGalleryBlock.innerBlocks.slice(0, getBlockIndex(clientId) + 1),
      );

      if (
        !isLastGalleryItem ||
        (isLastGalleryItem &&
          (!nextBlock || nextBlock.name !== PARAGRAPH_BLOCK_ID))
      ) {
        insertBlocks(createBlock(PARAGRAPH_BLOCK_ID, {}), rootBlockIndex + 1);
      }

      if (!isLastGalleryItem) {
        insertBlocks(
          createBlock(
            GALLERY_BLOCK_ID,
            { journalId, entryId },
            currentGalleryBlock.innerBlocks.slice(getBlockIndex(clientId) + 1),
          ),
          rootBlockIndex + 2,
        );
      }

      selectionChange(getNextBlockClientId(rootClientId), "content", 0, 0);
    });
  };

  return {
    moveMediaToPosition,
    calculateMediaSizes,
    createGalleryFromBlocks,
    createGalleryFromMoments,
    splitGalleryBlock,
  };
};
