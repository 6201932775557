import { Button } from "@wordpress/components/";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Icon } from "@/components/Icon";
import { ItemGroup, Item, GroupHeading } from "@/components/ItemGroup";
import { ButtonItem } from "@/components/ItemGroup/ButtonItem";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { ParticipantInfo } from "@/components/SharedJournals/ParticipantInfo";
import { JournalParticipant } from "@/data/repositories/V6API";
import {
  modalRouterViewState,
  primaryViewState,
  viewStates,
} from "@/view_state/ViewStates";

type Props = {
  participants: JournalParticipant[];
  journalId: string | null;
  handleCreateInvite: () => void;
};

export const SharedJournalMembers: React.FC<Props> = observer(
  ({ participants, journalId, handleCreateInvite }) => {
    const { __ } = useI18n();
    const currentUser = primaryViewState.user;
    const grouped = participants.reduce(
      (
        acc: { owner: JournalParticipant[]; participant: JournalParticipant[] },
        p,
      ) => {
        if (p.role === "owner") {
          acc.owner.push(p);
        } else if (p.role === "participant") {
          acc.participant.push(p);
        }
        return acc;
      },
      { owner: [], participant: [] },
    );

    const { owner, participant } = grouped;

    const sortedParticipants = participant.toSorted((a, b) =>
      a.name.toLocaleLowerCase() >= b.name.toLocaleLowerCase() ? 1 : -1,
    );

    if (owner.length === 0) {
      return null;
    }
    const ownerId = owner[0].id;
    const isUserOwner = ownerId === currentUser?.id;

    const ItemComponent = journalId ? ButtonItem : Item;

    return (
      <>
        {isUserOwner && (
          <p
            sx={{
              fontSize: 1,
              lineHeight: "body",
              color: "textPrimary",
              mt: 2,
              p: 1,
            }}
          >
            {__(
              "Shared journals are a private space for your closest friends and family to share life updates and memories. ",
            )}
            <EditLinkButton
              sx={{ fontSize: 1 }}
              onClick={() =>
                modalRouterViewState.sharedJournalFeatures("owner")
              }
              title={__("How it works")}
              label={__("How it works")}
            />
          </p>
        )}
        <GroupHeading>{__("Owner")}</GroupHeading>
        <ItemGroup>
          <ItemComponent
            key={ownerId}
            onClick={() => {
              if (journalId) {
                viewStates.modalRouter.showSharedJournalMember(
                  journalId,
                  ownerId,
                );
              }
            }}
          >
            <ParticipantInfo
              userId={ownerId}
              sx={{ textAlign: "left" }}
              size={1}
              noMarginBottom
            />
          </ItemComponent>
        </ItemGroup>
        {(participant.length > 0 || isUserOwner) && (
          <>
            <GroupHeading>
              {__("Members")} ({sortedParticipants.length})
            </GroupHeading>
            <ItemGroup>
              {sortedParticipants?.map((participant) => (
                <ItemComponent
                  key={participant.id}
                  onClick={() => {
                    if (journalId) {
                      viewStates.modalRouter.showSharedJournalMember(
                        journalId,
                        participant.id,
                      );
                    }
                  }}
                >
                  <ParticipantInfo
                    userId={participant.id}
                    size={1}
                    sx={{ textAlign: "left" }}
                    noMarginBottom
                  />
                </ItemComponent>
              ))}
              {isUserOwner && (
                <Item key="add-invite">
                  <Button
                    variant="tertiary"
                    sx={{
                      width: "100%",
                      "&.components-button.components-button": {
                        color: "primary",
                      },
                    }}
                    onClick={handleCreateInvite}
                  >
                    <Icon icon="plus" />
                    {__("Add Members")}
                  </Button>
                </Item>
              )}
            </ItemGroup>
            {isUserOwner && (
              <p sx={{ fontSize: 0, color: "textSecondary", mt: 1, p: 2 }}>
                {__(
                  "Day One Premium members can create Shared Journals, others can participate for free.",
                )}{" "}
              </p>
            )}
          </>
        )}
      </>
    );
  },
);
SharedJournalMembers.displayName = "SharedJournalMembers";
