import { ThemeUICSSObject } from "@theme-ui/core";
import { get } from "@theme-ui/css";
import { z } from "zod";

import dayOneIconsJson from "@/components/icons/iconFont/DayOneIcons.json";
import { theme } from "@/styles/theme";

const IconsFontSchema = z.custom<keyof typeof dayOneIconsJson>(
  (icon) => typeof icon === "string" && icon in dayOneIconsJson,
);

export type IconFont = z.infer<typeof IconsFontSchema>;

interface Props {
  icon: IconFont;
  iconStyles?: ThemeUICSSObject;
  className?: string;
  /**
   *
   * Determines the size of whole icon, it use theme to lookup fontSize first, and fallback to normal pixel value
   * @defaultValue 5
   * @example
   * Passing fontSize value
   * ```
   * size={2} // leads to 16px based on theme.fontSizes
   * ```
   * @example
   * Passing normal size value, this used to be using for iconSize on WP Icon component
   * ```
   * size={16}
   * ```
   */
  size?: number | string;
}

export const Icon = ({ icon, iconStyles, className, size = 5 }: Props) => {
  const getSize = (t: typeof theme) => {
    const iconSize = get(t, `fontSizes.${size}`);
    if (iconSize) {
      return `${iconSize}px`;
    }
    return typeof size === "string" ? size : `${size}px`;
  };

  return (
    <i
      className={`icon-${icon} ${className}`}
      sx={{
        height: (t) => getSize(t),
        "&&::before": {
          fontSize: (t) => getSize(t),
          ...iconStyles,
        },
      }}
    />
  );
};
