import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { MomentModel } from "@/data/models/MomentModel";

interface MomentData {
  moment: MomentModel | null;
  blob: Blob | null;
  thumbnailBlob: Blob | null;
}

export const useMoment = (
  journalId: string | undefined,
  entryId: string | undefined,
  clientId: string | undefined,
  subscribe = false,
) => {
  const [momentData, setMomentData] = useState<MomentData>({
    moment: null,
    blob: null,
    thumbnailBlob: null,
  });

  const [loading, setLoading] = useState(true);
  const momentStore = d1Classes.momentStore;

  useEffect(() => {
    if (!clientId || !entryId || !journalId) {
      return;
    }
    let done = false;

    const loadMomentBlob = async (moment: MomentModel) => {
      const blob = await momentStore.getMomentData(moment);
      if (!done && blob) {
        setMomentData((data) => ({
          ...data,
          blob: new Blob([blob], { type: moment.contentType }),
        }));
      }
    };

    const loadThumbnailBlob = async (moment: MomentModel) => {
      const thumbnailBlob = await momentStore.getMomentThumbnail(moment);
      if (!done && thumbnailBlob) {
        setMomentData((data) => ({
          ...data,
          thumbnailBlob: new Blob([thumbnailBlob]),
        }));
      }
    };

    const loadMomentBlobs = async () => {
      const moment = await momentStore.getMomentById(
        journalId,
        entryId,
        clientId,
      );
      if (!done) {
        setMomentData((data) => ({ ...data, moment }));
        if (moment) {
          await Promise.all([
            loadThumbnailBlob(moment),
            loadMomentBlob(moment),
          ]).catch((err: any) => {
            Sentry.captureException(err);
          });

          setLoading(false);
        }
      }
    };

    const unsubscribe = subscribe
      ? momentStore.subscribeToMomentById(
          (updatedMoment: MomentModel) => {
            setMomentData((currentData) => {
              return { ...currentData, moment: updatedMoment };
            });
          },
          journalId,
          entryId,
          clientId,
        )
      : () => {};

    loadMomentBlobs();

    return () => {
      done = true;
      unsubscribe();
    };
  }, [clientId, entryId, journalId, subscribe]);

  return {
    blob: momentData.blob,
    thumbnailBlob: momentData.thumbnailBlob,
    moment: momentData.moment,
    isLoading: loading,
  };
};

export type useMomentThumbState = { loading: boolean; url: string | null };
// The sidebar just wants to fetch thumbnails and nothing else,
// So here's a hook that doesn't load the original
export const useMomentThumb = (
  journalId: string,
  entryId: string,
  clientId?: string,
) => {
  const [state, setState] = useState<useMomentThumbState>({
    loading: true,
    url: null,
  });
  const momentStore = d1Classes.momentStore;

  useEffect(() => {
    let done = false;
    const load = async () => {
      const url = clientId
        ? await momentStore.getThumbnailUrl(journalId, entryId, clientId)
        : null;
      if (!done) {
        setState(() => ({ loading: false, url }));
      }
    };
    load().catch((err: any) => {
      Sentry.captureException(err);
    });

    return () => {
      done = true;
    };
  }, [clientId, entryId, journalId]);

  return state;
};
