import { Icon } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { topRightButtonStyles } from "@/components/SyncableImage/TopRightButton";

export const FullscreenButton = ({
  onClick,
  icon,
  className,
}: {
  onClick: () => void;
  icon: JSX.Element;
  className?: string;
}) => {
  const { __ } = useI18n();

  return (
    <div
      role="button"
      sx={{ ...topRightButtonStyles, "> svg": { fill: "white" } }}
      onClick={onClick}
      className={className}
      aria-label={__("View full screen")}
    >
      <Icon icon={icon} />
    </div>
  );
};
