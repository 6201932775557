import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance, createBlock } from "@wordpress/blocks";
import { __experimentalDivider as Divider } from "@wordpress/components";
import { useDispatch, useSelect } from "@wordpress/data";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";
import { D1Dropdown } from "@/components/D1Dropdown";
import { PARAGRAPH_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { Icon } from "@/components/Icon";
import { topRightButtonStyles } from "@/components/SyncableImage/TopRightButton";
import { MomentModel } from "@/data/models/MomentModel";
import { useSubscription } from "@/hooks/d1_hooks";
import { useGalleryMedia } from "@/hooks/useGalleryMedia";
import { dateTimeToString } from "@/utils/date-helper";

interface Props {
  journalId: string;
  entryId: string;
  momentId: string;
  clientId: BlockInstance["clientId"];
  rotateLeft?: () => void;
}

export const MediaMenu = ({
  journalId,
  entryId,
  momentId,
  clientId,
  rotateLeft,
}: Props) => {
  const { __ } = useI18n();
  const { insertBlock, selectionChange } = useDispatch(blockEditorStore);
  const {
    getBlock,
    getBlockIndex,
    getBlockRootClientId,
    getNextBlockClientId,
  } = useSelect(blockEditorStore, []);
  const { splitGalleryBlock } = useGalleryMedia();

  const momentSubscribed = useSubscription<MomentModel>(
    (cb) => {
      d1Classes.momentStore.subscribeToMomentById(
        cb,
        journalId,
        entryId,
        momentId,
      );
    },
    [entryId, journalId, momentId],
  );

  const toggleFavorite = async () => {
    await d1Classes.momentStore.toggleFavorite(journalId, entryId, momentId);
  };

  const insertTextBelow = () => {
    const galleryBlockClientId = getBlockRootClientId(clientId);

    if (galleryBlockClientId) {
      splitGalleryBlock(clientId, journalId, entryId);
    } else {
      // for single media
      const nextBlock = getBlock(getNextBlockClientId(clientId));

      if (!nextBlock || nextBlock.name !== PARAGRAPH_BLOCK_ID) {
        insertBlock(
          createBlock(PARAGRAPH_BLOCK_ID, {}),
          getBlockIndex(clientId) + 1,
        );
      }

      selectionChange(getNextBlockClientId(clientId), "content", 0, 0);
    }
  };

  if (!momentSubscribed) {
    return null;
  }

  return (
    <D1Dropdown
      popoverProps={{ placement: "bottom-start", variant: "primary" }}
      renderToggle={({ onToggle }) => (
        <div
          aria-label={__("Media menu")}
          onClick={onToggle}
          role="button"
          sx={{ "&&": { ...topRightButtonStyles } }}
        >
          <Icon icon="dots-horizontal" />
        </div>
      )}
      renderContent={() => (
        <div
          sx={{
            backgroundColor: "surface_light1_dark4",
            minWidth: "200px",
            fontSize: 1,
          }}
        >
          <div
            sx={{
              alignItems: "center",
              color: "textTertiary",
              display: "flex",
              fontSize: 1,
              p: 2,
            }}
          >
            {dateTimeToString(
              new Date(momentSubscribed.data.date),
              momentSubscribed.data.metadata?.timeZoneName ||
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              undefined,
              false,
            )}
          </div>
          <Divider sx={{ color: "borderPrimary" }} />
          <div sx={{ py: 2.5, "& button": { mt: 1 } }}>
            <MenuButton onClick={toggleFavorite}>
              {!momentSubscribed?.data.favorite
                ? __("Favorite")
                : __("Unfavorite")}
            </MenuButton>
            <MenuButton onClick={insertTextBelow}>
              {__("Insert Text Below")}
            </MenuButton>
            {rotateLeft && (
              <MenuButton onClick={rotateLeft}>{__("Rotate Left")}</MenuButton>
            )}
          </div>
        </div>
      )}
    />
  );
};
