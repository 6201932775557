import { BlockEditProps, registerBlockType } from "@wordpress/blocks";
import { Button, Flex } from "@wordpress/components";
import { useDispatch } from "@wordpress/data";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { CloseButton } from "@/components/CloseButton";
import { DICTATION_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { createParagraphBlock } from "@/components/Editor/rtj2gb/rtj2gb";
import { ESCAPE } from "@/components/Editor/utils/keycodes";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";

export const DictationBlock: React.FC<
  BlockEditProps<Record<string, never>>
> = ({ clientId }) => {
  const { replaceBlocks } = useDispatch("core/block-editor");
  const [preview, setPreview] = useState<string>("");
  const [interimText, setInterimText] = useState<string>("");
  const [speechRecognitionSupported, setSpeechRecognitionSupported] =
    useState(false);
  const { __ } = useI18n();
  const [listening, setListening] = useState(false);
  const [exit, setExit] = useState(false);
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(
    null,
  );

  const startDictation = async () => {
    setListening(true);
    try {
      recognition?.start();
    } catch (e: any) {
      console.error(e.message);
    }
  };
  const stopDictation = async () => {
    try {
      recognition?.stop();
    } catch (e: any) {
      console.error(e.message);
    }
  };
  const accept = async () => {
    replaceBlocks(clientId, [
      createParagraphBlock(preview || ""),
      createParagraphBlock(""),
    ]);
  };

  const handleEscape = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === ESCAPE) {
      setExit(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, true);
    return () => document.removeEventListener("keydown", handleEscape, true);
  }, []);

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognition();

      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onstart = () => {
        setListening(true);
      };

      recognitionInstance.onresult = (event: SpeechRecognitionEvent) => {
        console.group("loading");
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            setPreview((prevPreview) => `${prevPreview} ${transcript}`);
          } else {
            interimTranscript += transcript;
          }
        }
        setInterimText(interimTranscript);

        console.groupEnd();
      };

      recognitionInstance.onend = () => {
        setListening(false);
      };

      recognitionInstance.onerror = (error: Event) => {
        console.error("SpeechRecognition Error:", error);
      };

      setRecognition(recognitionInstance);
      setSpeechRecognitionSupported(true);
    } else {
      setSpeechRecognitionSupported(false);
    }
  }, []);

  // This should never happen since the block won't even appear
  if (!speechRecognitionSupported) {
    return <div>Speech recognition is not supported</div>;
  }
  if (exit) {
    return null;
  }

  return (
    <div sx={{ p: "3", bg: "surface_light2_dark2", borderRadius: "3" }}>
      <Flex gap="3" direction="column">
        <Flex>
          <b>{__("Dictate your content")}</b>
          <CloseButton
            onClick={() => setExit(true)}
            sx={{ position: "static" }}
          />
        </Flex>

        <Flex direction="column" gap={2} sx={{ fontSize: "0.8rem" }}>
          <p>
            {__("Experiemntal content dictation block.")}{" "}
            {__("Start the dictation, talk and stop/restart to add content.")}{" "}
            {__("When you are done click Accept to insert your text.")}
          </p>

          <div
            sx={{
              bg: "surface_light1_dark1",
              p: 3,
              border: "1px solid",
              borderColor: "borderPrimary",
              borderRadius: "3",
            }}
          >
            {preview} {interimText}
          </div>
        </Flex>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Flex sx={{ justifyContent: "start" }}>
            <Button
              variant="primary"
              onClick={startDictation}
              disabled={listening}
            >
              {listening
                ? "Listening"
                : preview === ""
                  ? "Start Dictation"
                  : "Continue Dictation"}
            </Button>
            {listening && (
              <Button
                variant={preview != null ? "secondary" : "primary"}
                onClick={stopDictation}
              >
                {__("Stop dictating")}
              </Button>
            )}
          </Flex>
          <Flex sx={{ justifyContent: "end" }}>
            <Button
              isDestructive
              disabled={listening || preview === ""}
              onClick={() => {
                setPreview("");
                setInterimText("");
              }}
            >
              Clear
            </Button>
            <Button
              variant="primary"
              disabled={listening || preview === ""}
              onClick={accept}
            >
              {__("Accept")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export const register = () => {
  if (!blockIsRegistered(DICTATION_BLOCK_ID)) {
    registerBlockType(DICTATION_BLOCK_ID, {
      edit: DictationBlock,
      title: "Dictation",
      category: "common",
      description: "A block that inserts dictated content",
      attributes: {},
      supports: {
        inserter: false,
      },
    });
  }
};
