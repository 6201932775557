import {
  Flex,
  FlexItem,
  __experimentalText as Text,
} from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";

import { Icon } from "@/components/Icon";
import { Item, ItemGroup } from "@/components/ItemGroup";

type Props = { count: number };

export const EncryptedJournalsInfo: React.FC<Props> = ({ count }) => {
  const { _n } = useI18n();

  const encryptedJournalLabel = sprintf(
    _n("%d Locked Journal", "%d Locked Journals", count),
    count,
  );

  if (!count) return null;
  return (
    <ItemGroup addMargin={true}>
      <Item>
        <FlexItem
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "calc(100% - 24px)",
          }}
        >
          <Flex
            sx={{
              "&& .journal-name": {
                ml: 3,
                color: "textSecondary",
                width: "calc(100% - 24px)",
              },
              "& div": {
                ml: 0,
              },
            }}
          >
            <div sx={{ width: "16px", height: "16px", mr: 1 }}>
              <Icon icon="lock-filled" size={2} sx={{ color: "textPrimary" }} />
            </div>
            <Text className="journal-name" truncate>
              {encryptedJournalLabel}
            </Text>
          </Flex>
        </FlexItem>
      </Item>
    </ItemGroup>
  );
};
