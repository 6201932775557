// Import just the core blocks we need.
// @ts-ignore
import * as coreCode from "@wordpress/block-library/build-module/code";
// @ts-ignore
import * as coreHeading from "@wordpress/block-library/build-module/heading";
// @ts-ignore
import * as coreHtml from "@wordpress/block-library/build-module/html";
// @ts-ignore
import * as coreList from "@wordpress/block-library/build-module/list";
// @ts-ignore
import * as listItem from "@wordpress/block-library/build-module/list-item";
// @ts-ignore
import * as coreParagraph from "@wordpress/block-library/build-module/paragraph";
// @ts-ignore
import * as coreQuote from "@wordpress/block-library/build-module/quote";
// @ts-ignore
import * as coreSeparator from "@wordpress/block-library/build-module/separator";
import {
  BlockInstance,
  getBlockTypes,
  registerBlockType,
  createBlock,
  setDefaultBlockName,
} from "@wordpress/blocks";
import { addFilter } from "@wordpress/hooks";

// Import the Day One blocks.
import {
  register as registerAudioBlock,
  unregister as unregisterAudioBlock,
} from "@/components/Editor/blocks/audio/AudioBlock";
import {
  emptyChecklistItem,
  register as registerChecklistBlock,
} from "@/components/Editor/blocks/checklist/CheckListItemBlock";
import {
  PARAGRAPH_BLOCK_ID,
  HEADING_BLOCK_ID,
  LIST_BLOCK_ID,
  CHECKLIST_BLOCK_ID,
  HTML_BLOCK_ID,
  SEPARATOR_BLOCK_ID,
  CODE_BLOCK_ID,
  GALLERY_BLOCK_ID,
  PDF_BLOCK_ID,
  VIDEO_BLOCK_ID,
  AUDIO_BLOCK_ID,
  IMAGE_BLOCK_ID,
  PREVIEW_BLOCK_ID,
  CONTACT_BLOCK_ID,
  LOCATION_BLOCK_ID,
  PODCAST_BLOCK_ID,
  ACTIVITY_BLOCK_ID,
  SONG_BLOCK_ID,
  WORKOUT_BLOCK_ID,
  GENERIC_MEDIA_BLOCK_ID,
  STATE_OF_MIND_BLOCK_ID,
  LIST_ITEM_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { register as registerDictationBlock } from "@/components/Editor/blocks/experimental/DictationBlock";
import {
  register as registerGalleryBlock,
  unregister as unregisterGalleryBlock,
} from "@/components/Editor/blocks/gallery/GalleryBlock";
import {
  register as registerImageBlock,
  unregister as unregisterImageBlock,
} from "@/components/Editor/blocks/image/ImageBlock";
import {
  register as registerPDFBlock,
  unregister as unregisterPDFBlock,
} from "@/components/Editor/blocks/pdf/PdfBlock";
import { register as registerPreviewBlock } from "@/components/Editor/blocks/preview/PreviewBlock";
import {
  register as registerContactBlock,
  unregister as unregisterContactBlock,
} from "@/components/Editor/blocks/suggestions/ContactBlock";
import {
  register as registerGenericMediaBlock,
  unregister as unregisterGenericMediaBlock,
} from "@/components/Editor/blocks/suggestions/GenericMediaBlock";
import {
  register as registerLocationBlock,
  unregister as unregisterLocationBlock,
} from "@/components/Editor/blocks/suggestions/LocationBlock";
import {
  register as registerMotionActivityBlock,
  unregister as unregisterMotionActivityBlock,
} from "@/components/Editor/blocks/suggestions/MotionActivityBlock";
import {
  register as registerPodcastBlock,
  unregister as unregisterPodcastBlock,
} from "@/components/Editor/blocks/suggestions/PodcastBlock";
import {
  register as registerSongBlock,
  unregister as unregisterSongBlock,
} from "@/components/Editor/blocks/suggestions/SongBlock";
import {
  register as registerStateOfMindBlock,
  unregister as unregsiterStateOfMindBlock,
} from "@/components/Editor/blocks/suggestions/StateOfMindBlock";
import {
  register as registerWorkoutBlock,
  unregister as unregisterWorkoutBlock,
} from "@/components/Editor/blocks/suggestions/WorkoutBlock";
import {
  register as registerVideoBlock,
  unregister as unregisterVideoBlock,
} from "@/components/Editor/blocks/video/VideoBlock";
import { CustomCodeEdit } from "@/components/Editor/utils/customCodeEdit";

export const MediaBlocks = [
  GALLERY_BLOCK_ID,
  PDF_BLOCK_ID,
  VIDEO_BLOCK_ID,
  AUDIO_BLOCK_ID,
  IMAGE_BLOCK_ID,
  PREVIEW_BLOCK_ID,
  CONTACT_BLOCK_ID,
  LOCATION_BLOCK_ID,
  PODCAST_BLOCK_ID,
  ACTIVITY_BLOCK_ID,
  SONG_BLOCK_ID,
  WORKOUT_BLOCK_ID,
  GENERIC_MEDIA_BLOCK_ID,
  STATE_OF_MIND_BLOCK_ID,
];

export const unRegisterMediaBlocks = () => {
  [
    unregisterGalleryBlock,
    unregisterAudioBlock,
    unregisterPDFBlock,
    unregisterImageBlock,
    unregisterVideoBlock,
    unregisterContactBlock,
    unregisterLocationBlock,
    unregisterPodcastBlock,
    unregisterMotionActivityBlock,
    unregisterSongBlock,
    unregisterWorkoutBlock,
    unregisterGenericMediaBlock,
    unregsiterStateOfMindBlock,
  ].forEach((unregister) => unregister());
};

export const areMediaBlocksRegistered = () => {
  return MediaBlocks.some((block) => blockIsRegistered(block));
};

export const registerMediaBlocks = () => {
  [
    registerGalleryBlock,
    registerAudioBlock,
    registerPDFBlock,
    registerImageBlock,
    registerVideoBlock,
    registerContactBlock,
    registerLocationBlock,
    registerPodcastBlock,
    registerMotionActivityBlock,
    registerSongBlock,
    registerWorkoutBlock,
    registerGenericMediaBlock,
    registerStateOfMindBlock,
  ].forEach((register) => register());
};

const registrationFilter = (settings: BlockInstance, name: string) => {
  switch (name) {
    case PARAGRAPH_BLOCK_ID:
    case HEADING_BLOCK_ID:
    case LIST_BLOCK_ID:
      return {
        ...settings,
        attributes: {
          ...settings.attributes,
          placeholder: {
            // Don't display a placeholder for new blocks.
            default: " ",
          },
        },
      };
    case HTML_BLOCK_ID:
      return {
        ...settings,
        supports: {
          // @ts-ignore supports really does exist here
          ...settings.supports,
          inserter: false,
        },
      };
    case SEPARATOR_BLOCK_ID:
      return {
        ...settings,
        title: "Line",
      };
    case CHECKLIST_BLOCK_ID:
      return {
        ...settings,
        title: "Checklist",
      };
    case CODE_BLOCK_ID:
      return {
        ...settings,
        edit: CustomCodeEdit,
      };
    default:
      return settings;
  }
};

// Make sure you call this function once before using an editor or
// running a test that uses block functions.
export const registerBlocks = () => {
  addFilter(
    "blocks.registerBlockType",
    "dayone/block-registration",
    registrationFilter,
  );
  registerCoreBlocks();
  registerDayOneBlocks();
};

// Confirming if blocks are registered is mostly useful for HMR in development,
// but also if there is an unexpected re-run of the registration code in a single
// page load, no warnings will fire.
export const blockIsRegistered = (blockName: string) => {
  const registeredBlocks = getBlockTypes();
  return !!registeredBlocks.find((block) => block.name === blockName);
};

export const registerCoreBlocks = () => {
  // Gutenberg's registerCoreBlocks brings in many blocks we don't need.
  // This just registers the minimum blocks we need for Day One.
  const listWithCustomSettings = getListWithCustomSettings();

  [
    coreCode,
    coreHeading,
    coreQuote,
    coreSeparator,
    coreParagraph,
    listWithCustomSettings,
    listItem,
    coreHtml,
  ].forEach((block) => {
    if (!blockIsRegistered(block.name)) {
      registerBlockType(
        {
          name: block.name,
          ...block.metadata,
        },
        block.settings,
      );
    }
  });

  setDefaultBlockName(coreParagraph.name);
};

export const registerDayOneBlocks = () => {
  [
    registerGalleryBlock,
    registerAudioBlock,
    registerPDFBlock,
    registerChecklistBlock,
    registerImageBlock,
    registerVideoBlock,
    registerDictationBlock,
    registerPreviewBlock,
    registerContactBlock,
    registerLocationBlock,
    registerPodcastBlock,
    registerMotionActivityBlock,
    registerSongBlock,
    registerWorkoutBlock,
    registerGenericMediaBlock,
    registerStateOfMindBlock,
  ].forEach((register) => register());
};

function getListWithCustomSettings() {
  const listSettings = { ...coreList.settings };
  const listCustomTransforms = getListCustomTransforms();
  const listCustomSettings = {
    ...listSettings,
    transforms: listCustomTransforms,
  };
  return { ...coreList, settings: listCustomSettings };
}

// Lifted from Gutenberg's list block transforms.
// https://github.com/WordPress/gutenberg/blob/trunk/packages/block-library/src/list/transforms.js#L33
function getListContentFlat(
  blocks: BlockInstance[],
  indent = 1,
): BlockInstance[] {
  return blocks.flatMap(({ name, attributes, innerBlocks = [] }) => {
    if (name === LIST_ITEM_BLOCK_ID) {
      return [
        createBlock(CHECKLIST_BLOCK_ID, {
          ...emptyChecklistItem,
          indent,
          content: attributes.content,
        }),
        ...getListContentFlat(innerBlocks, indent + 1),
      ];
    }
    return getListContentFlat(innerBlocks, indent);
  });
}

function getListCustomTransforms() {
  const { from, to: _to } = coreList.settings.transforms;
  const to = [
    ..._to,
    {
      type: "block",
      isMultiBlock: false,
      blocks: [CHECKLIST_BLOCK_ID],
      transform: (
        _: BlockInstance["attributes"],
        innerBlocks: BlockInstance[],
      ) => getListContentFlat(innerBlocks),
    },
    {
      type: "block",
      isMultiBlock: false,
      blocks: [PARAGRAPH_BLOCK_ID],
      transform: (
        _: BlockInstance["attributes"],
        innerBlocks: BlockInstance["innerBlocks"],
      ) => {
        const paragraphBlocks = [];
        for (const block of innerBlocks) {
          paragraphBlocks.push(
            createBlock(PARAGRAPH_BLOCK_ID, {
              attributes: { content: block.attributes.content },
            }),
          );
        }
        return paragraphBlocks;
      },
    },
  ];
  return {
    to,
    from,
  };
}
