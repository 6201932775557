import {
  ExtendedFormatConfiguration,
  registerFormatType,
  toggleFormat,
} from "@wordpress/rich-text";

import { CONTEXT_FORMAT_TOOLBAR_SLOT_FILL } from "@/components/Editor/components/ContextFormattingToolbar";
import { ContextToolbarButton } from "@/components/Editor/components/ContextToolbarButton";
import {
  formatIsRegistered,
  matchFormattingTags,
} from "@/components/Editor/formats/utils";
import { Icon } from "@/components/Icon";

const NAME = "dayone/strikethrough";
const title = "Strikethrough";
const tagName = "s";
const TAGS = ["~~"];

export const strikethrough: ExtendedFormatConfiguration = {
  title,
  tagName,
  className: null,
  __unstableInputRule: (value) => matchFormattingTags(value, TAGS, NAME),
  edit({ isActive, value, onChange, onFocus }) {
    return (
      <CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
        <ContextToolbarButton
          icon={<Icon icon="strike" size={4} />}
          title={title}
          onClick={() => {
            onChange(toggleFormat(value, { type: NAME }));
            onFocus();
          }}
          isActive={isActive}
        />
      </CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
    );
  },
  inactive() {
    return (
      <ContextToolbarButton
        icon={<Icon icon="strike" size={4} />}
        title={title}
        disabled
      />
    );
  },
};

export const register = () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, strikethrough);
  }
};
