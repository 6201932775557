import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect } from "react";

import { Blurb } from "@/components/Blurb";
import { D1Modal } from "@/components/D1Modal";
import { Icon } from "@/components/Icon";
import { useDevice } from "@/data/hooks/LayoutProvider";
import {
  primaryViewState,
  userSettingsViewState,
} from "@/view_state/ViewStates";

type ModalProps = {
  onClose: () => void;
  user: string;
};

export const SharedJournalFeaturesModal = ({ onClose, user }: ModalProps) => {
  const { isMobile } = useDevice();
  const { __ } = useI18n();
  const currentUser = primaryViewState.user;
  const userIsOwner = user === "owner";

  useEffect(() => {
    if (currentUser) {
      userSettingsViewState.saveSettings({ shown_shared_journals_info: true });
    }
  }, []);
  return (
    <D1Modal
      title={
        userIsOwner
          ? __("How Shared Journals work")
          : __("Introducing Shared Journals")
      }
      onRequestClose={onClose}
      sx={{
        width: "420px",
        maxWidth: "100%",
        height: isMobile ? "auto" : "388px",
        maxHeight: "100%",
        "& .components-modal__content, & .components-modal__content::before": {
          p: "0",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          height: !isMobile ? "100%" : "auto",
          position: "relative",
          px: 6,
          py: 3,
          gap: 3,
          "& svg": { color: "primary", fill: "currentColor" },
        }}
      >
        {userIsOwner ? (
          <>
            <Blurb
              icon={<Icon icon="users" />}
              iconSize="30px"
              fontSize={2}
              main={__("Connect with your people")}
              tagline={__(
                "Share the invite link and approve up to 30 people to join your shared journal.",
              )}
            />
            <Blurb
              icon={<Icon icon="lock" />}
              iconSize="30px"
              fontSize={2}
              main={__("A private way to connect")}
              tagline={__(
                "Only people in your shared journal can view, create, and reply to entries.",
              )}
            />
            <Blurb
              icon={<Icon icon="configuration" />}
              iconSize="30px"
              fontSize={2}
              main={__("Stay in control")}
              tagline={__(
                "Revoke someone’s access, transfer journal ownership, or delete the shared journal anytime.",
              )}
            />
          </>
        ) : (
          <>
            <Blurb
              icon={<Icon icon="users" />}
              iconSize="30px"
              fontSize={2}
              main={__("Connect with your people")}
              tagline={__(
                "Shared journals are a private space for your closest friends and family to share life updates and memories.",
              )}
            />
            <Blurb
              icon={<Icon icon="video" />}
              iconSize="30px"
              fontSize={2}
              main={__("Cherish life’s moments ")}
              tagline={__(
                "Make your memories richer by adding photos, videos, or audio.",
              )}
            />
            <Blurb
              icon={<Icon icon="configuration" />}
              iconSize="30px"
              fontSize={2}
              main={__("A private way to connect")}
              tagline={__(
                "Only people in your shared journal can view, create, and reply to entries.",
              )}
            />
          </>
        )}
      </Flex>
    </D1Modal>
  );
};
