import { Flex, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { FormattingMenuDivider } from "@/components/Editor/components/FormattingMenuDivider";
import { MediaTypes } from "@/components/MediaView/MediaItem";
import { DateFilter } from "@/components/Search/DateFilter";
import { JournalFilter } from "@/components/Search/JournalFilter";
import { MediaFilter } from "@/components/Search/MediaFilter";
import { Pill } from "@/components/Search/Pill";
import { TagFilter } from "@/components/Search/TagFilter";
import { Activity, Location, Weather } from "@/data/db/migrations/entry";
import { TemplateDBRow } from "@/data/db/migrations/template";
import { Prompt } from "@/data/repositories/PromptsAPI";
import { ClientMeta, EntryMusic } from "@/data/repositories/V2API";
import { modalRouterViewState, viewStates } from "@/view_state/ViewStates";

export type JournalFilterOptions = {
  journalId?: string;
  name?: string;
  entryCount: number;
  color?: string;
  hasSynced: boolean;
};

export type TagFilterOptions = {
  tag: string;
  entryCount: number;
};

export type PromptFilterOptions = {
  prompt: Prompt;
  entryCount: number;
};

export type TemplateFilterOptions = {
  template: TemplateDBRow;
  entryCount: number;
};

export type CreationDeviceFilterOptions = {
  deviceName: ClientMeta["creationDevice"];
  entryCount: number;
};

export type WeatherFilterOptions = {
  weather: Weather;
  entryCount: number;
};

export type MusicFilterOptions = {
  music: EntryMusic;
  entryCount: number;
};

export type PlaceFilterOptions = {
  place: Location;
  entryCount: number;
};

export type ActivityFilterOptions = {
  activity: Activity;
  entryCount: number;
};

export type MediaFilterOptions = {
  media: MediaTypes;
  entryCount: number;
};

export type FilterOptions =
  | TagFilterOptions
  | PromptFilterOptions
  | TemplateFilterOptions
  | CreationDeviceFilterOptions
  | WeatherFilterOptions
  | MusicFilterOptions
  | PlaceFilterOptions
  | ActivityFilterOptions
  | MediaFilterOptions;

export const getFilterIdAndLabel = (
  type: MultipleFilterOptions["type"],
  option: FilterOptions,
  __: (text: string) => string,
) => {
  let optionId = "";
  let label = "";
  switch (type) {
    case "prompt": {
      const op = option as PromptFilterOptions;
      optionId = op.prompt.id;
      label = op.prompt.content;
      break;
    }
    case "tag": {
      const op = option as TagFilterOptions;
      optionId = op.tag;
      label = op.tag;
      break;
    }
    case "template": {
      const op = option as TemplateFilterOptions;
      optionId = op.template.id;
      label = op.template.title;
      break;
    }
    case "creationDevice": {
      const op = option as CreationDeviceFilterOptions;
      optionId = op.deviceName;
      label = op.deviceName;
      break;
    }
    case "weather": {
      const op = option as WeatherFilterOptions;
      optionId = op.weather?.code ?? "";
      label = op.weather?.description ?? "";
      break;
    }
    case "music": {
      const op = option as MusicFilterOptions;
      optionId = op.music.artist;
      label = op.music.artist;
      break;
    }
    case "place": {
      const op = option as PlaceFilterOptions;
      optionId = op.place?.placeName ?? "";
      label = op.place?.placeName ?? "";
      break;
    }
    case "activity": {
      const op = option as ActivityFilterOptions;
      optionId = op.activity;
      label = op.activity;
      break;
    }
    case "media": {
      const op = option as MediaFilterOptions;
      optionId = op.media;
      label = op.media;
      switch (op.media) {
        case "image":
          label = __("Image");
          break;
        case "video":
          label = __("Video");
          break;
        case "audio":
          label = __("Audio");
          break;
        case "pdfAttachment":
          label = __("PDF");
          break;
      }
      break;
    }
  }
  return { optionId, label };
};

export type MultipleFilterOptions =
  | {
      type: "tag";
      options: TagFilterOptions[];
    }
  | {
      type: "prompt";
      options: PromptFilterOptions[];
    }
  | {
      type: "template";
      options: TemplateFilterOptions[];
    }
  | {
      type: "creationDevice";
      options: CreationDeviceFilterOptions[];
    }
  | {
      type: "weather";
      options: WeatherFilterOptions[];
    }
  | {
      type: "music";
      options: MusicFilterOptions[];
    }
  | {
      type: "place";
      options: PlaceFilterOptions[];
    }
  | {
      type: "activity";
      options: ActivityFilterOptions[];
    }
  | {
      type: "media";
      options: MediaFilterOptions[];
    };

type Props = {
  children: React.ReactNode;
};

export const CenteredContent: React.FC<Props> = ({ children }) => (
  <div
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    {children}
  </div>
);

export const Loading = () => (
  <CenteredContent>
    <Spinner />
  </CenteredContent>
);

export const FilterPills: React.FC = observer(() => {
  const { search: searchViewState } = viewStates;
  const { journalStore } = d1Classes;
  const { __ } = useI18n();

  return (
    <div
      sx={{
        pt: 3,
        pb: 2,
        px: [4, 4],
        overflow: searchViewState.filterDropdownOpen ? "hidden" : "scroll",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        scrollbarWidth: "none",
      }}
    >
      <JournalFilter
        searchViewState={searchViewState}
        journalStore={journalStore}
        journals={viewStates.primary.visibleSyncableJournals}
      />
      <TagFilter searchViewState={searchViewState} />
      <DateFilter searchViewState={searchViewState} />
      <MediaFilter searchViewState={searchViewState} />
      <Flex sx={{ width: "auto" }}>
        <Pill
          text={__("Advanced")}
          filterCounter={searchViewState.filterCounter}
          isActive={!searchViewState.isNoFilter}
          onClick={() => {
            analytics.tracks.recordEvent(EVENT.buttonTap, {
              button_identifier: "searchFilter_advanced_search",
            });
            modalRouterViewState.showAdvancedSearch();
          }}
          icon="configuration"
          hideChevron
        />
      </Flex>
      <>
        <FormattingMenuDivider sx={{ m: 0 }} />
        <Flex
          sx={{
            width: "auto",
          }}
        >
          <Pill
            styles={{
              backgroundColor: "surface_light3_dark3",
              border: "surface_light3_dark3",
              "&:hover": {
                opacity: 0.8,
              },
            }}
            text={__("Clear filters")}
            onClick={() => {
              analytics.tracks.recordEvent(EVENT.searchFilterRemoved, {
                filter_type: "all",
              });
              searchViewState.resetFilter();
            }}
            icon={null}
            hideChevron
          />
        </Flex>
      </>
    </div>
  );
});
