import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { Icon } from "@/components/Icon";
import { resultsSx } from "@/components/Search/SearchResultsStatus";
import { useDownloadAllJournals } from "@/hooks/useDownloadAllJournals";
import { primaryViewState } from "@/view_state/ViewStates";
import {
  searchViewState,
  viewStates,
  syncViewState,
} from "@/view_state/ViewStates";

export const PartialResultsIndicator: React.FC = observer(() => {
  const { __, _n } = useI18n();
  const { searchResults } = searchViewState;
  const searchResultsLength = searchResults.length;
  const { setDownloadState, downloadState, setSyncState } =
    useDownloadAllJournals({
      stage: "BUTTON",
      phase: "IDLE",
    });

  const {
    modalRouter: { showKeyModal },
  } = viewStates;

  const { masterKeyString, userEncryptionKeyExists: hasCreatedMasterKey } =
    primaryViewState;

  const { state: syncState } = syncViewState;

  const hasEnteredMasterKey = !!masterKeyString;
  const requireMasterKeyBeforeDownload =
    hasCreatedMasterKey && !hasEnteredMasterKey;

  const masterKeyIsEnteredOrNotCreated = !requireMasterKeyBeforeDownload;

  const handleDownloadAllJournals = () => {
    if (requireMasterKeyBeforeDownload) {
      showKeyModal({
        openedFrom: "search",
        isCreatingSharedJournal: false,
      });
    }
    setDownloadState({ stage: "BUTTON", phase: "CLICKED" });
  };

  const { phase } = downloadState;

  // syncState will drive downloadState transitions in useDownloadAllJournals
  // we only want to progress state transitions after master key is entered
  // or master key is not required (ie it's not created)
  useEffect(() => {
    if (masterKeyIsEnteredOrNotCreated && phase === "CLICKED") {
      setSyncState(syncState);
    }
  }, [syncState, setSyncState, masterKeyIsEnteredOrNotCreated, phase]);

  return (
    <div sx={resultsSx}>
      <p sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Icon icon="info-circle" />
        {sprintf(
          _n("Found %d match.", "Found %d matches.", searchResultsLength),
          searchResultsLength,
        )}{" "}
        -{" "}
        <span>
          <strong>{__("Partial results.")}</strong>
          <button
            onClick={handleDownloadAllJournals}
            sx={{
              backgroundColor: "transparent",
              border: "none",
              color: "textSecondary",
              cursor: "pointer",
              ":hover": {
                color: "textTertiary",
              },
            }}
          >
            <span
              sx={{
                paddingBottom: 0, // Space between text and underline
                borderBottom: "1px solid",
                borderColor: "currentColor",
              }}
            >
              Download all journals
            </span>
          </button>
        </span>
        {__("for complete search.")}
      </p>
    </div>
  );
});
