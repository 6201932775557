import { MomentDBRow } from "@/data/db/migrations/moment";

export class MomentModel {
  constructor(public data: MomentDBRow) {}

  get metadata() {
    return {
      ...this.data,
    };
  }

  get id() {
    return this.data.id;
  }

  get entryId() {
    return this.data.entry_id;
  }

  get journalId() {
    return this.data.journal_id;
  }

  get md5_body() {
    return this.data.md5_body;
  }

  get md5_envelope() {
    return this.data.md5_envelope;
  }

  get height() {
    return this.data.height;
  }

  get width() {
    return this.data.width;
  }

  get contentType() {
    return this.data.content_type;
  }

  get pdfName() {
    return this.data.pdfName;
  }

  get thumbnail() {
    return {
      contentType: this.data.thumbnail_content_type,
      md5_body: this.data.thumbnail_md5_body,
      md5_envelope: this.data.thumbnail_md5_envelope,
      height: this.data.thumbnail_height,
      width: this.data.thumbnail_width,
    };
  }

  get isUploading() {
    return this.data._local_is_uploading || false;
  }
}

export function momentNeedsUpload(moment: MomentDBRow): boolean {
  return !!moment._local_created_locally && !moment._local_original_uploaded;
}
