import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { exportSymmetricKey } from "@/crypto/DOCryptoBasics/symmetric/exportSymmetricKey";
import { EncryptionKeyInfo, KeyInfo } from "@/crypto/types/lockedKeyInfo";
import { fromBase64, toBase64, fromHex, toHex } from "@/crypto/utils";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export async function getDecryptedKeyInfo(
  keyInfo: KeyInfo,
  vaultRepository: VaultRepository,
  debugContext: string,
) {
  const fingerprint = toHex(fromBase64(keyInfo.fingerprint));
  const journalKey = await vaultRepository.getJournalKey(fingerprint);
  const contentKey = await Symmetric.Key.decrypt(
    journalKey!,
    fromBase64(keyInfo.locked_key),
    debugContext,
  );
  return await exportSymmetricKey(contentKey);
}

export async function decryptLockedKeys(
  encryptedKeysInfo: EncryptionKeyInfo,
  vaultRepository: VaultRepository,
  debugContext: string,
) {
  return Promise.all(
    encryptedKeysInfo.locked_key_infos.map(async (keyInfo) => {
      const decryptedKey = await getDecryptedKeyInfo(
        keyInfo,
        vaultRepository,
        debugContext,
      );
      return {
        identifier: keyInfo.identifier,
        key: toBase64(decryptedKey),
      };
    }),
  );
}

export async function generateNewKeys(
  encKeysJSON: EncryptionKeyInfo,
  destinationJournalId: string,
  vaultRepository: VaultRepository,
  debugContext: string,
) {
  const vault = await vaultRepository.getVaultByJournalId(destinationJournalId);

  if (!vault) {
    throw new Error("Vault not found for destination journal");
  }

  const destJournalPublicKey = await Asymmetric.Public.fromPEM(
    vault.vault.keys[0].public_key,
  );
  const destJournalFingerprint = vault.vault.keys[0].fingerprint;
  const destJournalPrivateKey = await vaultRepository.getJournalKey(
    destJournalFingerprint,
  );

  if (!destJournalPublicKey || !destJournalPrivateKey) {
    throw new Error("Destination journal key not found");
  }

  return Promise.all(
    encKeysJSON.locked_key_infos.map(async (keyInfo) => {
      const decryptedKey = await getDecryptedKeyInfo(
        keyInfo,
        vaultRepository,
        debugContext,
      );

      const encryptedKey = await Asymmetric.Public.encrypt(
        destJournalPublicKey,
        decryptedKey,
      );

      const signature = await Asymmetric.Private.signArrayBuffer({
        userPrivateKey: destJournalPrivateKey,
        buffer: encryptedKey,
      });

      return {
        identifier: keyInfo.identifier,
        fingerprint: toBase64(fromHex(destJournalFingerprint)),
        signature,
        locked_key: toBase64(new Uint8Array(encryptedKey)),
      };
    }),
  );
}
