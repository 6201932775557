import { Button, Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { Blurb } from "@/components/Blurb";
import { Icon } from "@/components/Icon";

export const FeatureList = () => {
  const { __ } = useI18n();
  const [showMore, setShowMore] = useState(false);

  return (
    <Flex
      direction="column"
      align="center"
      sx={{
        mt: 5,
        bg: "secondaryPurple",
        borderRadius: "lg",
      }}
    >
      <Flex
        direction="column"
        align="center"
        sx={{
          py: 4,
          px: 4,
          overflow: "hidden",
          height: "250px",

          ...(showMore && {
            height: "auto",
          }),
        }}
      >
        <Blurb
          icon={<Icon icon="camera" />}
          iconColor="primaryPurple"
          main={__("Unlimited Photos and Videos")}
          tagline={__("A picture is worth a thousand words.")}
        />
        <Blurb
          icon={<Icon icon="device-mobile-desktop" />}
          iconColor="primaryPurple"
          main={__("Multiple Devices")}
          tagline={__("Start on one device, finish on another.")}
        />
        <Blurb
          icon={<Icon icon="books" />}
          iconColor="primaryPurple"
          main={__("Unlimited Journals")}
          tagline={__("Unlimited, secure, and automatic.")}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="sync" />}
          main={__("Real time sync")}
          tagline={__("Start on one device. Finish on another.")}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="file-pdf" />}
          main={__("PDF Embed")}
          tagline={__(
            "Manually add PDF files - a file cabinet in your pocket.",
          )}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="mail" />}
          main={__("Email to Entry")}
          tagline={__(
            "Compose, bcc, or forward email entries straight from your email inbox to your Day One journal.",
          )}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="color-palette" />}
          main={__("Bonus Journal Colors")}
          tagline={__(
            "Finally get that hot magenta journal you've always wanted.",
          )}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="users" />}
          main={__("Shared Journals")}
          tagline={__("Create shared journals with family or friends.")}
        />
        <Blurb
          iconColor="primaryPurple"
          icon={<Icon icon="bookmark" />}
          main={__("Support Day One")}
          tagline={__(
            "We'll never sell your data or run ads in the app, so subscription funds are what keep Day One innovating.",
          )}
        />
      </Flex>
      {!showMore && (
        <Button
          text={__("See More")}
          label={__("See More")}
          onClick={() => setShowMore(true)}
          className="see-more-button"
          sx={{
            "&.see-more-button": {
              m: 3,
              height: "auto",
              fontSize: "12px",
              lineHeight: "12px",
              color: "primaryPurple",
            },
          }}
        />
      )}
    </Flex>
  );
};
